import { Box, Link, Typography } from "@mui/material"
import React, { useEffect, useMemo, useState } from "react"

interface Segment {
  index: number
  start: number
  end: number
  text: string
}
function srtTimestampToNumber(timestamp: string) {
  const [start, millis] = timestamp.split(",")
  const [hours, minutes, seconds] = start.split(":")
  return ((parseInt(hours) * 60 + parseInt(minutes)) * 60 + parseInt(seconds)) * 1000 + parseInt(millis)
}
export const CaptionPlayer: React.FC<{
  srt: string
  audioPlayer: HTMLAudioElement
  name: string | undefined
  url: string
}> = ({ srt, audioPlayer, name, url }) => {
  const segments: Segment[] = useMemo(() => {
    return srt
      .split("\n\n")
      .filter(chunk => chunk.split("\n").length >= 2)
      .map(chunk => {
        const lines = chunk.trim().split("\n")
        const index = parseInt(lines[0].trim())
        const [startString, endString] = lines[1].split(" --> ")
        const start = srtTimestampToNumber(startString)
        const end = srtTimestampToNumber(endString)
        let text = lines.slice(2).join(" ").trim()
        if (text.startsWith("Unknown: ")) {
          text = text.replace("Unknown:", name ? `${name}: ` : "")
        }
        return { index, start, end, text }
      })
  }, [srt, name])

  const [currentTime, setCurrentTime] = useState(0)
  const [running, setRunning] = useState(true)

  useEffect(() => {
    setRunning(!audioPlayer.paused)
    const onPlay = () => {
      setRunning(true)
    }
    const onPause = () => {
      setRunning(false)
    }
    const onSeeked = () => {
      setCurrentTime(audioPlayer.currentTime * 1000)
    }
    audioPlayer.addEventListener("play", onPlay)
    audioPlayer.addEventListener("pause", onPause)
    audioPlayer.addEventListener("seeked", onSeeked)
    return () => {
      audioPlayer.removeEventListener("play", onPlay)
      audioPlayer.removeEventListener("pause", onPause)
      audioPlayer.removeEventListener("seeked", onSeeked)
    }
  }, [audioPlayer])

  useEffect(() => {
    setCurrentTime(audioPlayer.currentTime * 1000)
    if (!running) {
      return
    }
    const captionTimer = setInterval(() => {
      setCurrentTime(audioPlayer.currentTime * 1000)
    }, 512)
    return () => {
      setCurrentTime(audioPlayer.currentTime * 1000)
      clearInterval(captionTimer)
    }
  }, [audioPlayer, running])

  const currents = useMemo(() => {
    const current = segments.findIndex(({ end }) => currentTime < end)
    if (current === -1) {
      return undefined
    }
    return { current: segments[current], next: segments[current + 1] }
  }, [segments, currentTime])

  return (
    <Typography component="div" sx={{ minHeight: "calc(2.4em + 16px)", paddingTop: 1, paddingBottom: 3 }}>
      {currents && <Box dangerouslySetInnerHTML={{ __html: currents.current ? currents.current.text : "" }} />}
      {currents && currents.next && (
        <Box dangerouslySetInnerHTML={{ __html: currents.next ? currents.next.text : "" }} />
      )}
      <Link
        onClick={() => window.open(`/transcript?url=${encodeURIComponent(url)}&name=${encodeURIComponent(name)}`)}
        sx={{
          fontSize: "0.7em",
          float: "right",
          "&:hover": {
            textDecoration: "underline",
          },
          cursor: "pointer",
        }}
      >
        Captions in Separate Window
      </Link>
    </Typography>
  )
}
