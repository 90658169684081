import { Schedulable, ScheduleKinds, canSelfSchedule, canUseSchedule, checkRole } from "@cs124/api"
import { HeadRoles } from "@cs124/person"
import { usePersonable } from "@cs124/personable"
import AddCircle from "@mui/icons-material/AddCircle"
import Cancel from "@mui/icons-material/Cancel"
import {
  Alert,
  AlertTitle,
  Avatar,
  Box,
  Container,
  IconButton,
  List,
  ListItem,
  TextField,
  Typography,
  darken,
} from "@mui/material"
import { green, grey, red, yellow } from "@mui/material/colors"
import gravatar from "gravatar"
import sortBy from "lodash/sortBy"
import moment from "moment-timezone"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { REFERENCE, useAllSchedules, useScheduleKind } from "."
import { API_SERVER, GRAVATAR_OPTIONS } from "../../constants"
import { useTicker } from "../helper"
import { StaffLanguage } from "../language"
import { TOPBAR_OFFSET } from "../layout"
import { P } from "../material-ui"
import makeLightDark from "../material-ui/makeLightDark"

export const Schedulabler: React.FC<{ kind: ScheduleKinds; noContainer?: boolean }> = ({ kind, noContainer }) => {
  const {
    all,
    update,
    status: { disabled },
  } = useScheduleKind(kind)
  const { course, headers } = usePersonable()
  const { status } = useAllSchedules()

  useEffect(() => {
    fetch(`${API_SERVER}/v1/schedulable/schedule/${kind}`, { headers, credentials: "include" })
      .then(response => response.json())
      .then(() => {
        update()
      })
  }, [headers, kind, update])

  let error = null
  let warning = null

  if (!["instructor", "head", "headta", "ta", "associate", "assistant"].includes(course?.role?.role)) {
    error = (
      <Alert severity="warning">
        <AlertTitle>Unauthorized</AlertTitle>
        <P>The scheduling page is not available to your role.</P>
      </Alert>
    )
  } else if (!course?.language) {
    error = (
      <Alert severity="warning">
        <AlertTitle>Please Choose a Language</AlertTitle>
        <P>You must select a language before scheduling tutoring site hours.</P>
        <hr />
        <StaffLanguage />
      </Alert>
    )
  } else if (disabled) {
    error = (
      <Alert severity="warning">
        <AlertTitle>Scheduling Disabled</AlertTitle>
        <P>Scheduling is disabled at this time.</P>
      </Alert>
    )
  }

  const selfSchedule = canSelfSchedule(course?.staffRole, status.canSelfSchedule)

  if (!error && !selfSchedule) {
    warning = (
      <Alert severity="warning">
        <AlertTitle>Please Schedule With Your Mentor</AlertTitle>
        <P>
          Contact your mentor{" "}
          {course?.mentor ? <Typography variant="kbd">{`${course?.mentor}@illinois.edu`}</Typography> : null} to adjust
          your tutoring site schedule.
          {course?.staffRole === "associate" && (
            <Box component="span">(You can still schedule your Assistant mentees.)</Box>
          )}
        </P>
      </Alert>
    )
  }

  const content = useMemo(() => {
    let lastDay
    return (
      <List>
        {all.map((schedulable, i) => {
          const showDay = schedulable.day !== lastDay
          lastDay = schedulable.day
          return (
            <Box key={i}>
              {showDay && (
                <ListItem
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    borderBottom: "1px solid grey",
                  }}
                >
                  <Typography variant="h3" display="block">
                    {schedulable.day}
                  </Typography>
                </ListItem>
              )}
              <Spot kind={kind} schedulable={schedulable} canSelfSchedule={selfSchedule} />
            </Box>
          )
        })}
      </List>
    )
  }, [kind, all, selfSchedule])

  if (noContainer) {
    return (
      <>
        {error ? (
          error
        ) : (
          <>
            {warning}
            {content}
          </>
        )}
      </>
    )
  } else {
    return (
      <Container maxWidth="md" sx={{ marginTop: `${TOPBAR_OFFSET + 64}px` }}>
        {error ? (
          error
        ) : (
          <>
            {warning}
            {content}
          </>
        )}
      </Container>
    )
  }
}

const Spot: React.FC<{
  kind: ScheduleKinds
  schedulable: Schedulable
  canSelfSchedule: boolean
}> = ({ kind, schedulable, canSelfSchedule }) => {
  const { update, status } = useScheduleKind(kind)
  const { course, headers, staffByEmail } = usePersonable()

  const netIDs = sortBy(schedulable.emails.sort(), email => (email === course.you.email ? 0 : 1)).map(
    email => email.split("@")[0]
  )

  const { now } = useTicker()
  const [who, setWho] = useState<string>("")
  const [validStaff, setValidStaff] = useState(false)

  const handleChange = event => {
    setWho(event.target.value)
  }

  const isValidStaff = useCallback(
    (who: string): boolean => {
      if (!course || !course.you) {
        return false
      }
      if (!staffByEmail[`${who}@illinois.edu`]) {
        return false
      }
      return checkRole(course.staffRole, who, course.you.netID, course.mentees ?? [], status.canSelfSchedule)
    },
    [course, staffByEmail, status]
  )

  useEffect(() => {
    setValidStaff(isValidStaff(who))
  }, [isValidStaff, who])

  const currentOffset = useMemo(() => {
    const startOffset =
      moment(now).tz("America/Chicago").startOf("week").utcOffset() - moment(now).tz("America/Chicago").utcOffset()
    return now - moment(now).tz("America/Chicago").startOf("week").valueOf() - startOffset * 60 * 1000
  }, [now])

  const add = useCallback(
    (start, who) => {
      fetch(`${API_SERVER}/v1/schedulable/add/${who}/${kind}/${start}`, {
        method: "PUT",
        headers,
        credentials: "include",
      })
        .then(response => response.json())
        .then(() => {
          update()
        })
        .finally(() => {
          setWho("")
        })
    },
    [kind, headers, update]
  )

  const remove = useCallback(
    (start, who) => {
      fetch(`${API_SERVER}/v1/schedulable/remove/${who}/${kind}/${start}`, {
        method: "DELETE",
        headers,
        credentials: "include",
      })
        .then(response => response.json())
        .then(() => {
          update()
        })
        .finally(() => {
          setWho("")
        })
    },
    [kind, headers, update]
  )

  const headRole = HeadRoles.includes(course?.staffRole)
  const me = schedulable.emails.includes(course.you?.email)
  const noLeave =
    !headRole &&
    schedulable.start - status.lockoutHours * 60 * 60 * 1000 < currentOffset &&
    currentOffset < schedulable.end
  const noAdd = !headRole && schedulable.start < currentOffset && currentOffset < schedulable.end

  const action = !validStaff
    ? () => {
        return
      }
    : netIDs.includes(who)
      ? () => !noLeave && remove(schedulable.start, who)
      : () => !noAdd && add(schedulable.start, who)

  const background = makeLightDark(
    {
      backgroundColor:
        noAdd || (me && noLeave) ? grey[100] : me ? green[100] : netIDs.length === 0 ? red[50] : "inherit",
    },
    {
      backgroundColor:
        noAdd || (me && noLeave)
          ? darken(grey[800], 0.5)
          : me
            ? darken(green[800], 0.5)
            : netIDs.length === 0
              ? darken(red[800], 0.5)
              : "inherit",
    }
  )

  const avatar = (
    <Avatar
      sx={{
        height: 32,
        width: 32,
        border:
          noAdd || (me && noLeave)
            ? `2px solid ${grey[500]}`
            : me
              ? `2px solid ${red[600]}`
              : `2px solid ${green[600]}`,
        marginRight: 2,
      }}
      src={gravatar.url(course.you.email, {
        s: "48",
        ...GRAVATAR_OPTIONS,
      })}
      onClick={() => {
        me
          ? !noLeave && remove(schedulable.start, course.you.netID)
          : !noAdd && add(schedulable.start, course.you.netID)
      }}
    />
  )

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: 1,
        borderTop: "1px dashed LightGrey",
        ...background,
        borderLeft: me
          ? `8px solid ${green[600]}`
          : noLeave && !noAdd
            ? `8px solid ${yellow[600]}`
            : `8px solid transparent`,
      }}
    >
      <Typography variant="body1" sx={{ minWidth: 144, textAlign: "right", marginRight: 2 }}>
        {moment.tz(REFERENCE, "America/Chicago").add(schedulable.start).format("h A")}&ndash;
        {moment.tz(REFERENCE, "America/Chicago").add(schedulable.end).format("h A")}
      </Typography>
      {canSelfSchedule ? avatar : <Box sx={{ width: 48 }} />}
      {canUseSchedule(course.staffRole, status.canSelfSchedule) && (
        <TextField
          disabled={noAdd || (me && noLeave)}
          label={!canSelfSchedule ? "Mentee NetID" : "NetID"}
          size="small"
          margin="none"
          sx={{ minWidth: "10em", maxWidth: "10em" }}
          value={who}
          onChange={handleChange}
          inputProps={{ spellCheck: "false" }}
        />
      )}
      <Box sx={{ width: 56 }}>
        {!(!validStaff || noAdd || (me && noLeave)) && (
          <IconButton onClick={action} size="large">
            {netIDs.includes(who) ? (
              <Cancel sx={{ color: "red" }} />
            ) : (
              <AddCircle
                sx={{ color: "green", visibility: !validStaff || noAdd || (me && noLeave) ? "hidden" : "visible" }}
              />
            )}
          </IconButton>
        )}
      </Box>
      <Box sx={{ flex: 1, display: "flex", flexWrap: "wrap" }}>
        {netIDs.map((netID, i) => (
          <Box
            component="span"
            key={i}
            sx={{ cursor: "pointer", marginRight: 2, whiteSpace: "nowrap", lineHeight: 1.1 }}
            onClick={() => (isValidStaff(netID) ? setWho(netID) : setWho(""))}
          >
            <Typography variant="caption">
              {staffByEmail[`${netID}@illinois.edu`]?.name || "Unnamed Staff"}{" "}
              <Typography variant="kbd">({netID})</Typography>
            </Typography>
            {i !== netIDs.length - 1 ? "," : ""}
          </Box>
        ))}
      </Box>
    </Box>
  )
}
