import { Box, Container } from "@mui/material"
import { grey } from "@mui/material/colors"
import React from "react"
import { makeLightDark } from "../material-ui"

export const BlockQuote: React.FC<object> = props => {
  return (
    <Container
      sx={{
        ...makeLightDark(
          {
            borderLeft: `2px solid ${grey[400]}`,
          },
          {
            borderLeft: `2px solid ${grey[600]}`,
          }
        ),
      }}
    >
      <Box
        component="blockquote"
        sx={{
          margin: 0,
        }}
        {...props}
      />
    </Container>
  )
}
