import { Breakpoint, useTheme } from "@mui/material/styles"
import React, { CSSProperties, ReactNode, useMemo } from "react"
import { MinWidth } from "./MinWidth"

type Side = "right" | "left"
export interface StickyBarProps {
  side: Side
  top?: number
  center: Breakpoint
  minWidth: number
  sx?: CSSProperties
  children: ReactNode
}
export const StickyBar: React.FC<StickyBarProps> = ({ side, center, minWidth, children, ...props }) => {
  const theme = useTheme()

  const top = props.top !== undefined ? props.top : theme.gap(10)

  const width = useMemo(() => `calc((100vw - ${theme.breakpoints.values[center]}px) / 2)`, [theme, center])
  const maxHeight = useMemo(() => `calc(100vh - ${top}px)`, [top])

  const sx: CSSProperties = {
    top,
    width,
    maxHeight,
    ...props.sx,
  }
  side === "right" ? (sx.right = 0) : (sx.left = 0)
  return (
    <MinWidth
      width={minWidth}
      sx={{
        position: "fixed",
        overflowX: "hidden",
        overflowY: "hidden",
        "&:hover": {
          overflowY: "auto",
        },
        ...sx,
      }}
    >
      {children}
    </MinWidth>
  )
}
