import { Schedulable, ScheduleKinds, SchedulingStatus, emptyStatus } from "@cs124/api"
import { usePersonable } from "@cs124/personable"
import moment from "moment-timezone"
import React, { PropsWithChildren, useCallback, useContext, useEffect, useState } from "react"
import { Array } from "runtypes"
import { API_SERVER } from "../../constants"

export interface AllScheduleContext {
  all: Schedulable[]
  current: Schedulable[]
  status: SchedulingStatus
  update: () => void
}
export const AllScheduleContext = React.createContext<AllScheduleContext>({
  all: [],
  current: [],
  status: emptyStatus,
  update: () => {
    return
  },
})
export interface ScheduleContext {
  all: Schedulable[]
  current: Schedulable | undefined
  update: () => void
  status: SchedulingStatus
}
export const ScheduleContext = React.createContext<ScheduleContext>({
  all: [],
  current: undefined,
  update: () => {
    return
  },
  status: emptyStatus,
})
export const ScheduleProvider: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  const [updater, setUpdater] = useState(true)
  const [currentUpdater, setCurrentUpdater] = useState(true)
  const { headers } = usePersonable()

  const [all, setAll] = useState<Schedulable[]>([])
  const [current, setCurrent] = useState<Schedulable[]>([])
  const [status, setStatus] = useState<SchedulingStatus>(emptyStatus)

  useEffect(() => {
    fetch(`${API_SERVER}/v1/schedulable/schedule`, { headers, credentials: "include" })
      .then(response => response.json())
      .then(response => {
        setAll(Array(Schedulable).check(response.schedulables))
      })
  }, [headers, updater])

  useEffect(() => {
    fetch(`${API_SERVER}/v1/schedulable/status`, { headers, credentials: "include" })
      .then(response => response.json())
      .then(response => {
        const newStatus = SchedulingStatus.check(response)
        setStatus(newStatus)
      })
  }, [headers, updater])

  useEffect(() => {
    const now = new Date().valueOf()
    const currentOffset = now - moment(now).tz("America/Chicago").startOf("week").valueOf()
    setCurrent(Array(Schedulable).check(all.filter(({ start, end }) => start < currentOffset && currentOffset < end)))
  }, [all, currentUpdater])

  useEffect(() => {
    const timer = setInterval(
      () => {
        setUpdater(u => !u)
      },
      1024 * 60 * 60
    )
    const currentTimer = setInterval(() => {
      setCurrentUpdater(u => !u)
    }, 1024 * 60)
    return () => {
      clearInterval(timer)
      clearInterval(currentTimer)
    }
  }, [])

  const update = useCallback(() => {
    setUpdater(u => !u)
  }, [])

  return (
    <AllScheduleContext.Provider
      value={{
        all,
        current,
        update,
        status,
      }}
    >
      {children}
    </AllScheduleContext.Provider>
  )
}

export const useAllSchedules = (): AllScheduleContext => useContext(AllScheduleContext)
export const useScheduleKind = (_kind: ScheduleKinds): ScheduleContext => {
  const { all, current, update, status } = useContext(AllScheduleContext)
  return {
    all: all.filter(({ kind }) => kind === _kind),
    current: current.find(({ kind }) => kind === _kind),
    update,
    status,
  }
}
