import { active, atBottom, atTop, useElementTracker } from "@cs124/element-tracker"
import React, { PropsWithChildren, useCallback, useContext, useEffect, useState } from "react"

export interface HashContext {
  available: boolean
  hash: string
}
const HashContext = React.createContext<HashContext>({
  available: false,
  hash: " ",
})

export interface HashProviderProps {
  filter?: (element: Element) => boolean
  top?: number
  delay?: number
}
export const HashProvider: React.FC<PropsWithChildren<HashProviderProps>> = ({
  children,
  filter = (): boolean => true,
  top = 0,
  delay = 0,
}) => {
  const [hash, setHash] = useState((typeof window !== "undefined" && window.location.hash) || " ")

  const _setHash = useCallback(
    (newHash: string) => {
      if (hash !== newHash) {
        setHash(newHash)
        const pathName = `${window.location.pathname}${newHash.trim().length > 0 ? `${newHash}` : ""}`
        window.history.replaceState({ ...window.history.state, as: pathName, url: pathName }, "", pathName)
      }
    },
    [hash]
  )

  const { elements } = useElementTracker()

  const [ready, setReady] = useState(delay === 0)
  useEffect(() => {
    if (delay === 0) {
      setReady(true)
    }
    const timer = setTimeout(() => {
      setReady(true)
    }, delay)
    return () => {
      clearTimeout(timer)
    }
  }, [delay])

  useEffect(() => {
    if (!ready) {
      return
    }

    if (atTop() && atBottom()) {
      return
    }
    if (atTop() && !atBottom()) {
      _setHash(" ")
      return
    }
    const activeHash =
      elements &&
      active(
        elements.filter(c => filter(c)),
        top
      )
    if (!activeHash) {
      _setHash(" ")
      return
    }
    const id = activeHash.getAttribute("data-et-id") || activeHash.id
    id && _setHash(`#${id}`)
  }, [filter, elements, top, _setHash, ready])

  useEffect(() => {
    const hashListener = (): void => {
      setHash(window.location.hash || " ")
    }
    window.addEventListener("hashchange", hashListener)
    return (): void => {
      window.removeEventListener("hashchange", hashListener)
    }
  }, [])

  return <HashContext.Provider value={{ available: true, hash }}>{children}</HashContext.Provider>
}

export const useHash = (): HashContext => {
  return useContext(HashContext)
}
