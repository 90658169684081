import { useHelpable } from "@cs124/helpable"
import { currentSemester, hasCurrentActiveRole } from "@cs124/person"
import { usePersonable } from "@cs124/personable"
import { Alert, AlertTitle, Button } from "@mui/material"
import { detect } from "detect-browser"
import { useSession } from "next-auth/react"
import React, { PropsWithChildren, ReactNode, useContext, useEffect, useMemo, useState } from "react"
import { LoginButton } from "~/components/login"
import { P } from "~/components/material-ui"
import { useScheduleKind } from "~/components/schedulable"
import { Promo } from "./Promo"

export interface HelpableCheckerContext {
  available: boolean
  ok: boolean
  error?: ReactNode
  warning?: ReactNode
}
const HelpableCheckerContext = React.createContext<HelpableCheckerContext>({
  available: false,
  ok: false,
})

export const HelpableChecker: React.FC<PropsWithChildren> = ({ children }) => {
  const { status } = useSession()
  const { course, loaded: courseLoaded } = usePersonable()
  const { connected } = useHelpable()
  const [counter, setCounter] = useState(true)
  const [browser, setBrowser] = useState<string>()
  const {
    status: { open },
  } = useScheduleKind("officeHours")

  const error = useMemo(() => {
    if (status === "unauthenticated") {
      return (
        <>
          <Alert severity="error">
            <AlertTitle>Please Sign In</AlertTitle>
            <P>Please sign in to use the tutoring site</P> <LoginButton sx={{ marginTop: 1 }} />
          </Alert>
          <Promo />
        </>
      )
    } else if (status === "authenticated" && courseLoaded && !hasCurrentActiveRole(course?.roles, currentSemester)) {
      return (
        <>
          <Alert severity="error">
            <AlertTitle>Access Denied</AlertTitle>
            <P>Restricted to current CS 124 students and staff</P>
          </Alert>
          <Promo />
        </>
      )
    } else if (!connected) {
      return (
        <Alert severity="warning">
          <AlertTitle>Loading Tutoring Site</AlertTitle>
          <P>The tutoring site is connecting...</P>
        </Alert>
      )
    } else if (!open && !course?.isStaff) {
      return (
        <Alert severity="warning">
          <AlertTitle>Tutoring Site Closed</AlertTitle>
          <P>CS 124 tutoring is not available at the moment</P>
        </Alert>
      )
    } else if (!(window && "Notification" in window && window.Notification)) {
      return (
        <Alert severity="error">
          <AlertTitle>No Notification Support</AlertTitle>
          <P>Your browser does not support notifications needed to use the tutoring site</P>
        </Alert>
      )
    } else if (Notification.permission !== "granted") {
      return (
        <Alert severity="error">
          <AlertTitle>Please Enable Notifications</AlertTitle>
          <P>You must enable browser notifications to use the tutoring site</P>
          <Button
            variant={"contained"}
            disableFocusRipple
            onClick={() => {
              Notification.requestPermission()
                .then(() => {
                  setCounter(!counter)
                })
                .catch(e => {
                  console.warn(e)
                })
            }}
          >
            Enable
          </Button>
        </Alert>
      )
    }
  }, [course, status, open, connected, courseLoaded, counter])

  useEffect(() => {
    const _browser = detect()
    setBrowser(_browser.name)
  }, [])

  const warning = useMemo(() => {
    if (!courseLoaded) {
      return
    }
    if (!open && course?.isStaff) {
      return (
        <Alert severity="warning">
          <AlertTitle>Tutoring Site Closed</AlertTitle>
          <P>The tutoring site is currently closed to students.</P>
        </Alert>
      )
    }
    if (!browser || ["chrome", "firefox"].includes(browser)) {
      return
    }
    return (
      <Alert severity="warning">
        <AlertTitle>Works Best On Chrome or Firefox</AlertTitle>
        <P>If you have issues, please try Chrome or Firefox first.</P>
      </Alert>
    )
  }, [open, browser, course?.isStaff, courseLoaded])

  return (
    <HelpableCheckerContext.Provider value={{ available: true, ok: error === undefined, error, warning }}>
      {children}
    </HelpableCheckerContext.Provider>
  )
}

export const useHelpableChecker = (): HelpableCheckerContext => {
  return useContext(HelpableCheckerContext)
}
