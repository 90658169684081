import { currentSemester, ProctorRoles, ScoreRoles, SEMESTER } from "@cs124/person"
import { usePersonable } from "@cs124/personable"
import AccessibilityNewIcon from "@mui/icons-material/AccessibilityNew"
import AssignmentIcon from "@mui/icons-material/Assignment"
import BallotIcon from "@mui/icons-material/Ballot"
import CakeIcon from "@mui/icons-material/Cake"
import Code from "@mui/icons-material/Code"
import CompareArrowsIcon from "@mui/icons-material/CompareArrows"
import DoneOutlineIcon from "@mui/icons-material/DoneOutline"
import ExpandLess from "@mui/icons-material/ExpandLess"
import ExpandMore from "@mui/icons-material/ExpandMore"
import Feedback from "@mui/icons-material/Feedback"
import ForumIcon from "@mui/icons-material/Forum"
import GetAppIcon from "@mui/icons-material/GetApp"
import GroupIcon from "@mui/icons-material/Group"
import Help from "@mui/icons-material/Help"
import InfoIcon from "@mui/icons-material/Info"
import LightBulbIcon from "@mui/icons-material/Lightbulb"
import PanTool from "@mui/icons-material/PanTool"
import PersonIcon from "@mui/icons-material/Person"
import PlayCircleFilled from "@mui/icons-material/PlayCircleFilled"
import ScheduleIcon from "@mui/icons-material/Schedule"
import { Box, Divider, ListItemButton } from "@mui/material"
import Collapse from "@mui/material/Collapse"
import Drawer from "@mui/material/Drawer"
import List from "@mui/material/List"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import { useTheme } from "@mui/material/styles"
import moment from "moment"
import Link from "next/link"
import { useRouter } from "next/router"
import React, { ReactNode, useState } from "react"
import { MP_RELEASED, MP_STAFF_RELEASED } from "../../constants"

const Closer = React.forwardRef<HTMLAnchorElement, { href?: string; close: () => void; children: ReactNode }>(
  ({ href, close, children, ...props }, ref) => {
    const { asPath } = useRouter()
    if (asPath.split(/[?#]/)[0] === href) {
      return (
        <a ref={ref} {...props} onClick={close}>
          {children}
        </a>
      )
    } else {
      return (
        <a ref={ref} {...props} href={href}>
          {children}
        </a>
      )
    }
  }
)
Closer.displayName = "Closer"

const InfoMenu: React.FC<{ close: () => void }> = () => {
  const [open, setOpen] = useState(false)
  const isEarly = moment(currentSemester.start).add(2, "week").isAfter(moment())

  return (
    <>
      <ListItemButton onClick={() => setOpen(!open)}>
        <ListItemIcon>
          <InfoIcon />
        </ListItemIcon>
        <ListItemText primary="Info" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List>
          <Link href={`/syllabus/${SEMESTER}`} passHref legacyBehavior>
            <ListItemButton sx={{ paddingLeft: 4 }} component={Closer} close={close}>
              <ListItemText primary="Syllabus" />
            </ListItemButton>
          </Link>
          <Link href={`/calendar/`} passHref legacyBehavior>
            <ListItemButton sx={{ paddingLeft: 4 }} component={Closer} close={close}>
              <ListItemText primary="Calendar" />
            </ListItemButton>
          </Link>
          <Link href="/resources/" passHref legacyBehavior>
            <ListItemButton sx={{ paddingLeft: 4 }} component={Closer} close={close}>
              <ListItemText primary="Resources" />
            </ListItemButton>
          </Link>
          {isEarly && (
            <>
              <Link href="/proficiency/" passHref legacyBehavior>
                <ListItemButton sx={{ paddingLeft: 4 }} component={Closer} close={close}>
                  <ListItemText primary="Proficiency Exam" />
                </ListItemButton>
              </Link>
            </>
          )}
        </List>
      </Collapse>
    </>
  )
}

const DrawerContent: React.FC<{ close: () => void }> = ({ close }) => {
  const { course } = usePersonable()

  const showJoin = course?.isStaff === false && course?.extra?.canStaff === true
  const showLanguage = course?.role && !course?.language
  const isEarly = moment(currentSemester.start).add(2, "week").isAfter(moment())

  const topDivider = showJoin || showLanguage || isEarly

  const contentChunk = (
    <>
      <Link href="/lessons/" passHref legacyBehavior>
        <ListItemButton component={Closer} close={close}>
          <ListItemIcon>
            <LightBulbIcon />
          </ListItemIcon>
          <ListItemText primary="Learn" />
        </ListItemButton>
      </Link>
      <Link href="/practice/" passHref legacyBehavior>
        <ListItemButton component={Closer} close={close}>
          <ListItemIcon>
            <Code />
          </ListItemIcon>
          <ListItemText primary="Practice" />
        </ListItemButton>
      </Link>
      <Link href="/play/" passHref legacyBehavior>
        <ListItemButton component={Closer} close={close}>
          <ListItemIcon>
            <PlayCircleFilled />
          </ListItemIcon>
          <ListItemText primary="Play" />
        </ListItemButton>
      </Link>
      <Link href="/quizzes/" passHref legacyBehavior>
        <ListItemButton component={Closer} close={close}>
          <ListItemIcon>
            <BallotIcon />
          </ListItemIcon>
          <ListItemText primary="Quizzes" />
        </ListItemButton>
      </Link>
      {((course?.isStaff && MP_STAFF_RELEASED) || MP_RELEASED) && (
        <Link href="/MP/" passHref legacyBehavior>
          <ListItemButton component={Closer} close={close}>
            <ListItemIcon>
              <AssignmentIcon />
            </ListItemIcon>
            <ListItemText primary="MP" />
          </ListItemButton>
        </Link>
      )}
    </>
  )

  const helpChunk = (
    <>
      <Link href="/tutoring/" passHref legacyBehavior>
        <ListItemButton component={Closer} close={close}>
          <ListItemIcon>
            <PanTool />
          </ListItemIcon>
          <ListItemText primary="Online Tutoring" />
        </ListItemButton>
      </Link>
      <Link href="/schedule/" passHref legacyBehavior>
        <ListItemButton component={Closer} close={close}>
          <ListItemIcon>
            <ScheduleIcon />
          </ListItemIcon>
          <ListItemText primary="Tutoring Schedule" />
        </ListItemButton>
      </Link>
      <ListItemButton component="a" href="https://forum.cs124.org" target="_blank">
        <ListItemIcon>
          <ForumIcon />
        </ListItemIcon>
        <ListItemText primary="Forum" />
      </ListItemButton>
    </>
  )

  return (
    <>
      <List>
        {showJoin && (
          <Link href="/join/" passHref legacyBehavior>
            <ListItemButton component={Closer} close={close}>
              <ListItemIcon>
                <AccessibilityNewIcon />
              </ListItemIcon>
              <ListItemText primary="Join Our Staff" />
            </ListItemButton>
          </Link>
        )}
        {showLanguage && (
          <Link href="/choose/" passHref legacyBehavior>
            <ListItemButton component={Closer} close={close}>
              <ListItemIcon>
                <CompareArrowsIcon />
              </ListItemIcon>
              <ListItemText primary="Choose Your Language" />
            </ListItemButton>
          </Link>
        )}
        {isEarly && (
          <>
            <Link href="/start/" passHref legacyBehavior>
              <ListItemButton component={Closer} close={close}>
                <ListItemIcon>
                  <GetAppIcon />
                </ListItemIcon>
                <ListItemText primary="Start" />
              </ListItemButton>
            </Link>
            <Link href={`/syllabus/${SEMESTER}`} passHref legacyBehavior>
              <ListItemButton component={Closer} close={close}>
                <ListItemIcon>
                  <InfoIcon />
                </ListItemIcon>
                <ListItemText primary="Syllabus" />
              </ListItemButton>
            </Link>
            <Link href="/people/" passHref legacyBehavior>
              <ListItemButton component={Closer} close={close}>
                <ListItemIcon>
                  <GroupIcon />
                </ListItemIcon>
                <ListItemText primary="People" />
              </ListItemButton>
            </Link>
          </>
        )}
        {topDivider && <Divider />}
        {course?.isStaff ? helpChunk : contentChunk}
        <Divider />
        {course?.isStaff ? contentChunk : helpChunk}
        <Divider />
        <InfoMenu close={close} />
        <Divider />
        {(course?.role?.role === "student" || ScoreRoles.includes(course?.staffRole)) && (
          <>
            <Link href="/grades/" passHref legacyBehavior>
              <ListItemButton component={Closer} close={close}>
                <ListItemIcon>
                  <DoneOutlineIcon />
                </ListItemIcon>
                <ListItemText primary="Grades" />
              </ListItemButton>
            </Link>
          </>
        )}
        {!isEarly && (
          <Link href="/people/" passHref legacyBehavior>
            <ListItemButton component={Closer} close={close}>
              <ListItemIcon>
                <GroupIcon />
              </ListItemIcon>
              <ListItemText primary="People" />
            </ListItemButton>
          </Link>
        )}
        <Link href="/you/" passHref legacyBehavior>
          <ListItemButton component={Closer} close={close}>
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText primary="Profile" />
          </ListItemButton>
        </Link>
        <Link href="/feedback/" passHref legacyBehavior>
          <ListItemButton component={Closer} close={close}>
            <ListItemIcon>
              <Feedback />
            </ListItemIcon>
            <ListItemText primary="Feedback" />
          </ListItemButton>
        </Link>
        {course?.isStaff && (
          <>
            <Divider />
            <Link href="/howto/" passHref legacyBehavior>
              <ListItemButton component={Closer} close={close}>
                <ListItemIcon>
                  <Help />
                </ListItemIcon>
                <ListItemText primary="Staff Guide" />
              </ListItemButton>
            </Link>
            {ProctorRoles.includes(course?.staffRole) && (
              <Link href="/proctoring/" passHref legacyBehavior>
                <ListItemButton component={Closer} close={close}>
                  <ListItemIcon>
                    <CakeIcon />
                  </ListItemIcon>
                  <ListItemText primary="Proctoring" />
                </ListItemButton>
              </Link>
            )}
          </>
        )}
      </List>
    </>
  )
}

export const MenuDrawer: React.FC<{ open: boolean; onClose: () => void; close: () => void }> = ({
  open,
  onClose,
  close,
}) => {
  const theme = useTheme()
  return (
    <Box sx={{ display: "flex" }}>
      <Box component="nav" aria-label="site menu">
        <Drawer
          variant="temporary"
          anchor={theme.direction === "rtl" ? "right" : "left"}
          open={open}
          onClose={onClose}
          sx={{
            paper: {
              width: 240,
            },
          }}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <DrawerContent close={close} />
        </Drawer>
      </Box>
    </Box>
  )
}
