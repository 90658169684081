import PlayCircleFilled from "@mui/icons-material/PlayCircleFilled"
import { Box } from "@mui/material"
import CircularProgress from "@mui/material/CircularProgress"
import IconButton from "@mui/material/IconButton"
import Tooltip from "@mui/material/Tooltip"
import { green } from "@mui/material/colors"
import { useTheme } from "@mui/material/styles"
import React from "react"

export const RunButton: React.FC<{ running: boolean; disabled: boolean; run: () => void }> = ({
  running,
  disabled,
  run,
}) => {
  const theme = useTheme()
  return (
    <Tooltip
      title={"Run"}
      PopperProps={{
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, -10],
            },
          },
        ],
      }}
      placement="top"
      sx={{ overflow: "hidden", tooltipPlacementRight: { margin: 0 } }}
    >
      <Box>
        <IconButton size={"small"} disabled={disabled || running} sx={{ lineHeight: 0, padding: 0 }} onClick={run}>
          <PlayCircleFilled sx={{ color: green[700], fontSize: theme.spacing(4) }} />
          {running && (
            <CircularProgress
              sx={{ position: "absolute", bottom: 0, left: 0, color: green.A400 }}
              disableShrink
              size={theme.spacing(4)}
            />
          )}
        </IconButton>
      </Box>
    </Tooltip>
  )
}
