import { Box, Button, IconButton, Menu, MenuItem, MenuList, Slider, Typography } from "@mui/material"

import PauseRounded from "@mui/icons-material/PauseRounded"
import PlayArrowRounded from "@mui/icons-material/PlayArrowRounded"
import VolumeDownIcon from "@mui/icons-material/VolumeDownRounded"
import VolumeOffRounded from "@mui/icons-material/VolumeOffRounded"
import { detect } from "detect-browser"
import { useEffect, useRef, useState } from "react"
import { Number } from "runtypes"
import makeLightDark from "../material-ui/makeLightDark"
import { useShareable } from "./ShareableProvider"
import { formatDuration } from "./common"

function formatRate(value: number): string {
  if (value === 0.75) {
    return "0.75X"
  } else if (value === 1.25) {
    return "1.25X"
  } else {
    return `${(Math.round(value * 10) / 10).toFixed(1).toString()}X`
  }
}

const SPEED_OPTIONS = ["0.5", "0.75", "1.0", "1.25", "1.5", "2.0"]

export const PlayerControls: React.FC<{ player: HTMLAudioElement | undefined }> = ({ player }) => {
  const [state, setState] = useState<"playing" | "paused">(!player?.paused ? "playing" : "paused")
  const [duration, setDuration] = useState<number | undefined>(player?.duration ?? 0)
  const [currentTime, setCurrentTime] = useState(0)
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null)
  const [disabled, setDisabled] = useState(true)
  const { playbackRate, setPlaybackRate, volume, setVolume, muted, setMuted } = useShareable()
  const browser = detect()
  const [dragging, setDragging] = useState(false)

  const savedPlaybackRate = useRef(playbackRate)
  useEffect(() => {
    savedPlaybackRate.current = playbackRate
  }, [playbackRate])

  useEffect(() => {
    if (!player) {
      return
    }

    const onPause = () => setState("paused")
    const onPlay = () => setState("playing")
    const onDurationChanged = () => setDuration(player.duration)
    const onTimeUpdate = () => {} // setCurrentTime(player.currentTime)
    const onLoadedMetadata = () => {
      setDisabled(player.readyState === 0)
    }

    player.addEventListener("pause", onPause)
    player.addEventListener("play", onPlay)
    player.addEventListener("durationchange", onDurationChanged)
    player.addEventListener("seeked", onTimeUpdate)
    player.addEventListener("loadedmetadata", onLoadedMetadata)

    player.playbackRate = savedPlaybackRate.current
    setDisabled(player.readyState === 0)

    return () => {
      player.removeEventListener("pause", onPause)
      player.removeEventListener("play", onPlay)
      player.removeEventListener("durationchange", onDurationChanged)
      player.removeEventListener("seeked", onTimeUpdate)
      player.removeEventListener("loadedmetadata", onLoadedMetadata)
    }
  }, [player])

  useEffect(() => {
    if (!player) {
      return
    }
    player.volume = volume
  }, [player, volume])

  useEffect(() => {
    if (!player) {
      return
    }
    player.playbackRate = playbackRate
  }, [player, playbackRate])

  useEffect(() => {
    if (!player || state === "paused" || dragging) {
      return
    }
    const timer = setInterval(() => {
      setCurrentTime(player.currentTime)
    }, 50)

    return () => {
      clearInterval(timer)
    }
  }, [state, player, dragging])

  const hoverTimer = useRef<ReturnType<typeof setTimeout> | undefined>()
  const [volumeOpen, setVolumeOpen] = useState(false)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const color = makeLightDark({ color: "dimgrey" }, { color: "silver" })
  return (
    <Box sx={{ flex: 1, display: "flex", flexDirection: "row", alignItems: "center", marginRight: 1 }}>
      <IconButton
        size="small"
        onClick={() => {
          state === "paused" ? player.play() : player.pause()
        }}
        sx={{ marginRight: 1 }}
      >
        {state === "paused" ? <PlayArrowRounded sx={{ color }} /> : <PauseRounded sx={{ color }} />}
      </IconButton>
      <Typography variant="caption" sx={{ color, opacity: 0.8, marginRight: 1, userSelect: "none" }}>
        {formatDuration(currentTime)}
      </Typography>
      <Slider
        sx={{
          flex: 1,
          marginLeft: 1,
          marginRight: 1,
          color,
        }}
        disabled={disabled}
        size="small"
        min={0}
        step={0.1}
        max={duration}
        value={currentTime}
        onChange={(_, value) => {
          const newTime = Number.check(value)
          if (!(!player.paused && browser.name === "safari")) {
            player.currentTime = newTime
          }
          setCurrentTime(newTime)
          setDragging(true)
        }}
        onChangeCommitted={(_, value) => {
          const newTime = Number.check(value)
          player.currentTime = Number.check(value)
          setCurrentTime(newTime)
          setDragging(false)
        }}
      />
      <Typography variant="caption" sx={{ color, opacity: 0.8, marginLeft: 1, marginRight: 1, userSelect: "none" }}>
        {formatDuration(duration)}
      </Typography>
      <Box
        onMouseOver={() => {
          clearTimeout(hoverTimer.current)
          setVolumeOpen(true)
        }}
        onMouseLeave={() => {
          clearTimeout(hoverTimer.current)
          hoverTimer.current = setTimeout(() => {
            setVolumeOpen(false)
          }, 100)
        }}
        sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <Slider
          size="small"
          min={0}
          max={1.0}
          step={0.01}
          value={volume}
          onChange={(_, value) => {
            setVolume(Number.check(value))
          }}
          sx={{
            opacity: volumeOpen ? 1 : 0,
            width: volumeOpen ? 40 : 0,
            marginLeft: volumeOpen ? 1 : 0,
            marginRight: volumeOpen ? 1 : 0,
            transition: "opacity 0.3s ease-in-out, width 0.3s ease-in-out, margin 0.3s ease-in-out",
            color,
          }}
        />
        <IconButton
          size="small"
          onClick={() => {
            setMuted(!muted)
          }}
        >
          {muted ? <VolumeOffRounded sx={{ color }} /> : <VolumeDownIcon sx={{ color }} />}
        </IconButton>
      </Box>
      <Button size="small" sx={{ paddingLeft: 0.5, paddingRight: 0.5, minWidth: 0 }} onClick={handleClick}>
        <Typography variant="caption" sx={{ color, width: "4ch", textAlign: "center" }}>
          {formatRate(playbackRate)}
        </Typography>
      </Button>
      <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={handleClose}>
        <MenuList dense>
          {SPEED_OPTIONS.map((option, i) => (
            <MenuItem
              selected={playbackRate === parseFloat(option)}
              key={i}
              onClick={() => {
                const newRate = parseFloat(option)
                setPlaybackRate(newRate)
                handleClose()
              }}
            >
              <Typography variant="caption">{option}</Typography>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Box>
  )
}
