import { safeChangeValue } from "@cs124/monace"
import Restore from "@mui/icons-material/Restore"
import { Box, useTheme } from "@mui/material"
import Tooltip from "@mui/material/Tooltip"
import { grey } from "@mui/material/colors"
import React from "react"
import { IAceEditor } from "react-ace/lib/types"

export const RestoreButton: React.FC<{ editor: IAceEditor; defaultValue: string }> = ({ editor, defaultValue }) => {
  const theme = useTheme()
  return (
    <Tooltip
      title={"Restore"}
      placement="left"
      disableInteractive
      PopperProps={{
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, -10],
            },
          },
        ],
      }}
      sx={{ overflow: "hidden", tooltipPlacementLeft: { margin: 0 } }}
    >
      <Box
        sx={{
          lineHeight: 0,
          padding: 0,
        }}
      >
        <Restore
          sx={{
            color: grey[500],
            fontSize: theme.spacing(2),
          }}
          onClick={() => safeChangeValue(editor, defaultValue)}
        />
      </Box>
    </Tooltip>
  )
}
