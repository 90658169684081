import { usePersonable } from "@cs124/personable"
import { Alert, AlertTitle } from "@mui/material"
import React from "react"
import { A, P } from "../material-ui"

const capitalize = (language: string) => language.charAt(0).toUpperCase() + language.slice(1)

export const LanguageWarning: React.FC<{ language: string }> = ({ language }) => {
  const { course } = usePersonable()
  if (!course || course?.isStaff || !course.you) {
    return null
  }
  let message = null
  if (!course?.language) {
    message = (
      <Alert severity="warning">
        <AlertTitle>Language Not Selected</AlertTitle>
        <P>
          This material is in {capitalize(language)}. You have not yet selected a primary language. Please do so{" "}
          <A href="/choose/">by clicking here.</A>
        </P>
      </Alert>
    )
  } else if (course?.language !== language) {
    message = (
      <Alert severity="warning">
        <AlertTitle>Heads Up</AlertTitle>
        <P>
          This material is in {capitalize(language)}, but your primary language is {capitalize(course?.language)}.
          That&apos;s completely fine! Just wanted to let you know.
        </P>
      </Alert>
    )
  }
  return message
}
