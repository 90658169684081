import ExitToApp from "@mui/icons-material/ExitToApp"
import LockOpen from "@mui/icons-material/LockOpen"
import Button, { ButtonProps } from "@mui/material/Button"
import { signIn, signOut, useSession } from "next-auth/react"
import React from "react"

import { Box, Typography, useTheme } from "@mui/material"
import { green, grey } from "@mui/material/colors"
import makeLightDark from "../material-ui/makeLightDark"

export interface LoginButtonProps extends ButtonProps {
  iconOnly?: boolean
  loginText?: string
}
export const LoginButton: React.FC<LoginButtonProps> = ({ iconOnly = false, loginText = "Login", sx, ...props }) => {
  const theme = useTheme()

  const { status } = useSession()

  if (status === "loading") {
    return <Box sx={{ display: "inline-block", position: "relative", width: "5rem" }} />
  }

  let content
  if (status === "authenticated") {
    content = iconOnly ? <ExitToApp fontSize={"inherit"} /> : <Typography variant="caption">Logout</Typography>
  } else {
    content = iconOnly ? <LockOpen fontSize={"inherit"} /> : <Typography variant="caption">{loginText}</Typography>
  }

  return (
    <Box sx={{ display: "inline-block", position: "relative" }}>
      <Button
        variant={"contained"}
        disableElevation={status === "authenticated"}
        sx={{
          width: "5rem",
          height: theme.spacing(4),
          lineHeight: "normal",
          textTransform: "none",
          padding: 1,
          ...(iconOnly && {
            width: "2.4rem",
            minWidth: "2.4rem",
            fontSize: theme.spacing(3),
          }),
          ...makeLightDark(
            {
              color: grey[100],
              ...(status === "authenticated"
                ? {
                    backgroundColor: grey[500],
                    "&:hover": {
                      backgroundColor: grey[700],
                    },
                  }
                : {
                    backgroundColor: green[400],
                  }),
            },
            {
              color: grey[400],
              ...(status === "authenticated"
                ? {
                    backgroundColor: grey[700],
                    "&:hover": {
                      backgroundColor: grey[500],
                    },
                  }
                : {
                    backgroundColor: green[700],
                  }),
            }
          ),
          ...sx,
        }}
        onClick={() => {
          status === "authenticated" ? signOut() : status === "unauthenticated" && signIn("azure-ad")
        }}
        {...props}
      >
        {content}
      </Button>
    </Box>
  )
}
