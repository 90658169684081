import { useHelpable } from "@cs124/helpable"
import React, { PropsWithChildren, useCallback, useContext, useEffect, useRef } from "react"

export interface RefresherContext {
  refreshRequest: () => void
}

export const RefresherContext = React.createContext<RefresherContext>({
  refreshRequest: () => {
    throw "Not available"
  },
})

export const RequestRefresher: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  const { request, withdrawRequest } = useHelpable()
  const timerRef = useRef(null)

  const refreshRequest = useCallback(() => {
    timerRef.current && clearTimeout(timerRef.current)
    timerRef.current = setTimeout(() => {
      withdrawRequest("Timeout")
    }, 10240)
  }, [withdrawRequest])

  useEffect(() => {
    if (!request) {
      timerRef.current && clearTimeout(timerRef.current)
    } else {
      timerRef.current = setTimeout(() => {
        withdrawRequest("Timeout")
      }, 10240)
    }
    return () => {
      timerRef.current && clearTimeout(timerRef.current)
    }
  }, [request, withdrawRequest])

  return (
    <RefresherContext.Provider
      value={{
        refreshRequest,
      }}
    >
      {children}
    </RefresherContext.Provider>
  )
}
export const useRefresher = (): RefresherContext => {
  return useContext(RefresherContext)
}
