import Adjust from "@mui/icons-material/Adjust"
import { Box } from "@mui/material"
import CircularProgress from "@mui/material/CircularProgress"
import IconButton from "@mui/material/IconButton"
import Tooltip from "@mui/material/Tooltip"
import { red } from "@mui/material/colors"
import { useTheme } from "@mui/material/styles"
import React from "react"

export const RecordButton: React.FC<{ recording: boolean; toggle: () => void }> = ({ recording, toggle }) => {
  const theme = useTheme()
  return (
    <Tooltip
      title={"Record"}
      PopperProps={{
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, -10],
            },
          },
        ],
      }}
      placement="top"
      sx={{ overflow: "hidden", tooltipPlacementRight: { margin: 0 } }}
    >
      <Box>
        <IconButton sx={{ lineHeight: 0, padding: 0 }} onClick={toggle} size="large">
          <Adjust sx={{ color: red[700], fontSize: theme.spacing(4) }} />
          {recording && (
            <CircularProgress
              sx={{
                position: "absolute",
                bottom: 0,
                left: 0,
                color: red.A400,
              }}
              disableShrink
              size={theme.spacing(4)}
            />
          )}
        </IconButton>
      </Box>
    </Tooltip>
  )
}
