import { Box, Button } from "@mui/material"
import { useSession } from "next-auth/react"
import React, { useEffect, useState } from "react"
import YoutubePlayer from "react-youtube"
import { DEVELOPMENT } from "../../constants"

export const Promo: React.FC = () => {
  const { status } = useSession()

  const [promoSeen, setPromoSeen] = useState(false)

  useEffect(() => {
    setPromoSeen(localStorage.getItem("helpable:promoSeen") ? true : false)
  }, [])

  useEffect(() => {
    status === "authenticated" && promoSeen && localStorage.setItem("helpable:promoSeen", "true")
  }, [status, promoSeen])

  return (
    !promoSeen &&
    !DEVELOPMENT && (
      <>
        <Box sx={{ display: "flex", justifyContent: "center", marginBottom: 2 }}>
          <Box sx={{ flex: 1, maxWidth: 640 }}>
            <YoutubePlayer
              videoId="OlN8q2FP12U"
              className="yt-wrapper"
              iframeClassName="yt-iframe"
              opts={{ playerVars: { autoplay: 0 } }}
            />
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center", marginBottom: 2 }}>
          <Box sx={{ flex: 1, maxWidth: 640 }}>
            {status === "authenticated" && (
              <Button variant="outlined" onClick={() => setPromoSeen(true)}>
                Hide
              </Button>
            )}
          </Box>
        </Box>
      </>
    )
  )
}
