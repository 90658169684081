import { Box, Button } from "@mui/material"
import Image from "next/image"
import Link from "next/link"
import React, { Component, ErrorInfo, ReactNode } from "react"
import { Number, Partial, Record, Static, String } from "runtypes"
import { SEO } from "../../layouts"
import { H2, P } from "../material-ui"

interface ErrorState {
  hasError: boolean
  errorLine?: string
  componentStack?: string
}

const Fallback: React.FC<{ errorLine: string; componentStack: string | undefined }> = ({
  errorLine,
  componentStack,
}) => {
  const fullError = `${errorLine}${componentStack ? `\n${componentStack}` : ""}`
  return (
    <>
      <SEO title="Something Went Wrong" description="Something went wrong" />
      <Box
        sx={{
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box>
          <Image alt="learncs.online logo" src="/images/logo-460x460.png" width={460} height={460} />
        </Box>
        <Box sx={{ textAlign: "center", maxWidth: "50%" }}>
          <H2>Something Went Wrong</H2>
          <P>
            Drat! An error occurred. Please try reloading the page. Or use <Link href="/">this link</Link> to return to
            safety.
          </P>
          <P>
            Use the button below to copy the error to report on{" "}
            <Link target="_blank" href="https://forum.cs124.org">
              the course forum
            </Link>
            .
          </P>
        </Box>
        <Button
          variant="outlined"
          onClick={() => {
            navigator.clipboard.writeText(fullError)
          }}
        >
          Copy to Clipboard
        </Button>
        <Box sx={{ maxWidth: "100%" }}>
          <Box component="pre" sx={{ textAlign: "left", marginLeft: "10%" }}>
            {errorLine}
            {componentStack && componentStack}
          </Box>
        </Box>
      </Box>
    </>
  )
}

const stackLineRegex = new RegExp(/^at (?:(\S+) )?\(?(.*?):(\d+):(\d+)\)?$/)

const StackLine = Record({
  originalLine: String,
  source: String,
  line: Number,
  character: Number,
}).And(
  Partial({
    at: String,
  })
)
type StackLine = Static<typeof StackLine>

const reformatStackTrace = async (stackTrace: string): Promise<string> => {
  const stackLines = stackTrace
    .split("\n")
    .map(l => l.trim())
    .map(l => {
      const match = l.match(stackLineRegex)
      if (!match) {
        return l
      }
      return StackLine.check({
        originalLine: l,
        source: match[2],
        line: parseInt(match[3]),
        character: parseInt(match[4]),
        ...(match[1] && { at: match[1] }),
      })
    })
  const cleanedLines: string[] = []
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const sourceMaps: { [key: string]: any } = {}
  for (const stackLine of stackLines) {
    if (!StackLine.guard(stackLine)) {
      cleanedLines.push(stackLine)
    } else {
      let source = stackLine.source
      let line = stackLine.line
      if (source.startsWith("http")) {
        let consumer
        try {
          if (sourceMaps[stackLine.source]) {
            consumer = sourceMaps[stackLine.source]
          } else {
            const mappingJSON = await fetch(`${stackLine.source}.map`).then(r => r.json())
            consumer = await new window.sourceMap.SourceMapConsumer(mappingJSON)
          }
          sourceMaps[stackLine.source] = consumer
          const result = consumer.originalPositionFor({
            line: stackLine.line,
            column: stackLine.character,
          })
          source = result.source
          line = result.line
        } catch (err) {
          console.warn(err)
          cleanedLines.push(stackLine.originalLine)
        }
      }
      source = source.replace("webpack-internal:///", "")
      source = source.replace("webpack://_N_E/", "./")
      if (source.includes("node_modules")) {
        const allModules = source.split("node_modules")
        source = `node_modules${allModules[allModules.length - 1]}`
      }
      cleanedLines.push(`at ${source}:${line}`)
    }
  }
  for (const consumer of Object.values(sourceMaps)) {
    try {
      consumer.destroy()
    } catch (err) {}
  }
  return cleanedLines.map(l => `  ${l}`).join("\n")
}

export default class ErrorBoundary extends Component<{ children?: ReactNode }, ErrorState> {
  public state: ErrorState = {
    hasError: false,
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, errorLine: `${error.name}: ${error.message}` }
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    if (!info.componentStack) {
      return
    }
    const errorLine = `${error.name}: ${error.message}`
    reformatStackTrace(info.componentStack)
      .then(reformattedStackTrace => {
        // eslint-disable-next-line react/no-is-mounted
        this.setState({ hasError: true, errorLine, componentStack: reformattedStackTrace })
      })
      .catch(() => {
        // eslint-disable-next-line react/no-is-mounted
        this.setState({ hasError: true, errorLine, componentStack: info.componentStack })
      })
  }

  render() {
    return this.state.hasError ? (
      <Fallback errorLine={this.state.errorLine} componentStack={this.state.componentStack} />
    ) : (
      this.props.children
    )
  }
}
