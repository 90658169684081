import { Box } from "@mui/material"
import React, { CSSProperties } from "react"
import Children from "react-children-utilities"
import slugify from "slugify"
import { TOPBAR_OFFSET } from "../layout"
import { headings as MuiHeadings } from "../material-ui/Typography"

export interface HeadingProps {
  id?: string
  sx?: CSSProperties
  children: React.ReactNode
}
const Heading = (tag: "h1" | "h2" | "h3" | "h4" | "h5" | "h6"): React.FC<HeadingProps> => {
  const WrappedHeading: React.FC<HeadingProps> = ({ id, children, ...props }) => {
    let childs = React.Children.toArray(children)
    const last = childs[childs.length - 1]
    // This regex matches to preceding spaces and {#custom-id} at the end of a string.
    // Also, checks the text of node won't be empty after the removal of {#custom-id}.
    const match = /^(.*?)\s*\{#([\w-]+)\}$/.exec(Children.onlyText(last))
    if (match && (match[1] || childs.length > 1)) {
      id = match[2]
      // Remove the custom ID from the original text.
      if (match[1]) {
        childs = [...childs.slice(0, childs.length - 1), match[1]]
      } else {
        childs.pop()
      }
    } else {
      const text = Children.onlyText(children)
      id = id || slugify(text, { lower: true })
    }
    const Heading = MuiHeadings[tag]
    if (["h1", "h2", "h3"].includes(tag)) {
      return (
        <>
          <Box id={id} sx={{ position: "relative", top: -1 * TOPBAR_OFFSET }} />
          <Heading data-et={true} data-et-id={id} {...props}>
            {childs}
          </Heading>
        </>
      )
    } else {
      return <Heading>{childs}</Heading>
    }
  }
  return WrappedHeading
}

export const headings = {
  h1: Heading("h1"),
  h2: Heading("h2"),
  h3: Heading("h3"),
  h4: Heading("h4"),
  h5: Heading("h5"),
  h6: Heading("h6"),
}
export const H2 = Heading("h2")
export const H3 = Heading("h3")
