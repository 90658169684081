import { Typography } from "@mui/material"
import { A, LeadP, ListWrapper, P } from "../material-ui/Typography"
import { BlockQuote } from "./BlockQuote"
import { Code, Pre } from "./Code"
import { headings } from "./Headings"

export const components = {
  ...headings,
  p: P,
  code: Code,
  pre: Pre,
  a: A,
  LeadP,
  blockquote: BlockQuote,
  Comment: () => null,
  ul: (props: object) => <ListWrapper type="ul" {...props} />,
  ol: (props: object) => <ListWrapper type="ol" {...props} />,
  table: (props: object) => <Typography component="table" {...props} />,
  thead: (props: object) => <Typography variant="h4" component="thead" {...props} />,
  inlineCode: () => {
    throw "inlineCode not supported"
  },
}
export * from "./Code"
export * from "./Footnote"
export * from "./Headings"
export { A, P }
