import CheckCircle from "@mui/icons-material/CheckCircle"
import { Box } from "@mui/material"
import CircularProgress from "@mui/material/CircularProgress"
import Tooltip from "@mui/material/Tooltip"
import { green } from "@mui/material/colors"
import { useTheme } from "@mui/material/styles"
import React from "react"

export const SavingIndicator: React.FC<{ saving: boolean }> = ({ saving }) => {
  const theme = useTheme()
  return (
    <Tooltip
      title={saving ? "Saving" : "Saved"}
      placement="right"
      sx={{ overflow: "hidden", tooltipPlacementRight: { margin: 0 } }}
      PopperProps={{
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, -10],
            },
          },
        ],
      }}
    >
      <Box sx={{ lineHeight: 0, padding: 0 }}>
        <CheckCircle sx={{ color: green[400], fontSize: theme.spacing(2) }} />
        {saving && (
          <CircularProgress
            sx={{ position: "absolute", top: 0, right: 0, color: green.A700 }}
            disableShrink
            size={theme.spacing(2)}
          />
        )}
      </Box>
    </Tooltip>
  )
}
