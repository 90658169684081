import { useHelpable } from "@cs124/helpable"
import { usePersonable } from "@cs124/personable"
import PanTool from "@mui/icons-material/PanTool"
import { Badge, Box, Chip, IconButton } from "@mui/material"
import { useRouter } from "next/router"
import React, { useMemo } from "react"
import { useLesson } from "../lessons/LessonsProvider"
import makeLightDark from "../material-ui/makeLightDark"
import { useScheduleKind } from "../schedulable"

const openSX = {
  padding: 1,
  ...makeLightDark({ backgroundColor: "lightGreen" }, { backgroundColor: "darkGreen" }),
}

export const HelpableStatus: React.FC<{ style: "chip" | "icon"; noStaff?: boolean }> = ({ style, noStaff }) => {
  const router = useRouter()

  const { language: lessonLanguage, available } = useLesson()
  const { staffByEmail, course } = usePersonable()
  const { current } = useScheduleKind("officeHours")
  const { status } = useHelpable()
  const language = available ? lessonLanguage : course?.language

  const { kotlin, java, any } = useMemo(() => {
    if (!status?.presentStaff) {
      return { kotlin: 0, java: 0, any: 0 }
    }
    const kotlin = status.presentStaff.filter(
      email => staffByEmail[email]?.language === "kotlin" || staffByEmail[email]?.language === "both"
    ).length
    const java = status.presentStaff.filter(
      email => staffByEmail[email]?.language === "java" || staffByEmail[email]?.language === "both"
    ).length
    return { kotlin, java, any: status.presentStaff.length }
  }, [status?.presentStaff, staffByEmail])

  const helpCount = useMemo(() => {
    if (!course?.isStaff || !status || !status.requests) {
      return 0
    }
    return status.requests.filter(({ open, active, isStaff }) => open && active && !isStaff).length
  }, [course, status])

  if (!current || !language) {
    return null
  }
  if (current.kotlin + current.java === 0) {
    return null
  }
  if (language === "java" && (current.java === 0 || java === 0)) {
    return null
  }
  if (language === "kotlin" && (current.kotlin === 0 || kotlin === 0)) {
    return null
  }
  if (noStaff && course?.isStaff) {
    return null
  }
  const count = course?.isStaff ? any : language === "java" ? java : kotlin
  if (count === 0) {
    return null
  }
  if (style === "chip") {
    return (
      <Box sx={{ marginTop: 2 }}>
        <Badge badgeContent={count} color="secondary" overlap="rectangular">
          <Chip
            label="Tutoring Site Open"
            onClick={() => router.push("/tutoring/")}
            sx={{
              fontSize: "1.2em",
              marginLeft: 2,
              cursor: "pointer",
              ...openSX,
            }}
          />
        </Badge>
      </Box>
    )
  } else {
    if (helpCount === 0) {
      return (
        <Badge badgeContent={count} color="secondary" overlap="rectangular">
          <IconButton disableFocusRipple onClick={() => router.push("/tutoring/")} sx={openSX} size="large">
            <PanTool fontSize="small" />
          </IconButton>
        </Badge>
      )
    } else {
      return (
        <Badge badgeContent={helpCount} color="error" anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
          <Badge badgeContent={count} color="secondary" overlap="rectangular">
            <IconButton disableFocusRipple onClick={() => router.push("/tutoring/")} sx={openSX} size="large">
              <PanTool fontSize="small" />
            </IconButton>
          </Badge>
        </Badge>
      )
    }
  }
}
