import { usePathname } from "next/navigation"
import React, { createContext, PropsWithChildren, useContext, useEffect, useState } from "react"
import { String } from "runtypes"

export interface BuildIdContext {
  available: boolean
  clientBuildId: string
  serverBuildId: string
  buildIdMismatch: boolean
}
export const BuildIdContext = createContext<BuildIdContext>({
  available: false,
  clientBuildId: "",
  serverBuildId: "",
  buildIdMismatch: false,
})

export const BuildIdProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const pathName = usePathname()
  const [data, setData] = useState<{ clientBuildId: string; serverBuildId: string; buildIdMismatch: boolean }>({
    clientBuildId: "",
    serverBuildId: "",
    buildIdMismatch: false,
  })
  useEffect(() => {
    let busy = false
    async function checkBuildId() {
      if (busy) {
        console.warn(`Previous buildId check still active`)
        return
      }
      try {
        busy = true
        const clientBuildId = String.check(JSON.parse(document.querySelector("#__NEXT_DATA__").textContent).buildId)
        const serverBuildId = String.check(
          await fetch(`/api/buildId`)
            .then(r => r.json())
            .then(({ buildId }) => buildId)
        )
        setData({ clientBuildId, serverBuildId, buildIdMismatch: clientBuildId !== serverBuildId })
      } catch (err) {
        console.warn(`Error comparing build IDs: ${err}`)
      } finally {
        busy = false
      }
    }
    checkBuildId()
    const timer = setInterval(
      () => {
        checkBuildId()
      },
      10 * 60 * 1024
    )
    return () => {
      clearInterval(timer)
    }
  }, [pathName])

  return <BuildIdContext.Provider value={{ available: true, ...data }}>{children}</BuildIdContext.Provider>
}

export const useBuildId = (): BuildIdContext => {
  return useContext(BuildIdContext)
}
