import { Box } from "@mui/material"
import FormControl from "@mui/material/FormControl"
import MenuItem from "@mui/material/MenuItem"
import Paper from "@mui/material/Paper"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import React from "react"
import { A, P } from "../material-ui"
import { useSharing } from "./SharingProvider"

export const ShareableSharing: React.FC<{
  evenIfSet?: boolean
  noPaper?: boolean
}> = ({ evenIfSet, noPaper }) => {
  const { isSharing, setSharing, loaded } = useSharing()

  if (!loaded) {
    return null
  }
  if (isSharing !== undefined && !evenIfSet) {
    return null
  }

  const handleChange = (event: SelectChangeEvent<"yes" | "no">) => {
    const update = event.target.value
    setSharing(update === "yes" ? true : update === "no" ? false : undefined)
  }
  return (
    <Paper elevation={noPaper ? 0 : 2} sx={{ padding: 2, marginBottom: 2 }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box>
          <P sx={{ marginBottom: 0, fontSize: "1.4em", marginRight: 8 }}>
            I&apos;m willing to share my contributions on <A href="https://learncs.online">learncs.online</A>
          </P>
        </Box>
        <FormControl variant="outlined" size="small">
          <Select
            id="choose-sharing"
            value={isSharing === true ? "yes" : isSharing === false ? "no" : ""}
            onChange={handleChange}
            displayEmpty
          >
            <MenuItem disabled value={""}>
              Default (Yes)
            </MenuItem>
            <MenuItem value={"yes"}>Yes</MenuItem>
            <MenuItem value={"no"}>No</MenuItem>
          </Select>
        </FormControl>
      </Box>
    </Paper>
  )
}
