import { usePersonable } from "@cs124/personable"
import { Box, Paper } from "@mui/material"
import assert from "assert"
import getYouTubeID from "get-youtube-id"
import React, { PropsWithChildren } from "react"
import YoutubePlayer from "react-youtube"
import { useLessons } from "./LessonsProvider"

export const LessonVideo: React.FC<PropsWithChildren<{ url: string }>> = ({ url, children }) => {
  const { course } = usePersonable()
  const { preview } = useLessons()

  const id = getYouTubeID(url)
  assert(id)

  return (
    <Paper
      elevation={2}
      sx={{
        padding: 2,
        paddingBottom: 1,
        marginBottom: 2,
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "center", marginBottom: 2 }}>
        <Box sx={{ flex: 1, maxWidth: 640 }}>
          <YoutubePlayer
            videoId={id}
            className="yt-wrapper"
            iframeClassName="yt-iframe"
            opts={{ host: "https://www.youtube-nocookie.com", playerVars: { autoplay: 0, rel: 0, modestbranding: 1 } }}
          />
        </Box>
      </Box>
      {course?.isStaff && !preview && <>{children}</>}
    </Paper>
  )
}
