import moment from "moment-timezone"

export const REFERENCE = moment.tz("2020-01-01", "America/Chicago").startOf("week")

export const MAP_DAY = {
  Sunday: 0,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
}
export const DAY_MAP = {
  0: "Sunday",
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
}

export * from "./Schedulabler"
export * from "./ScheduleProvider"
export * from "./ShowSchedule"
