import { CSSProperties } from "react"
import { theme } from "./theme"

export default function makeLightDark(light: CSSProperties, dark: CSSProperties) {
  return {
    ...light,
    '[data-mui-color-scheme="dark"] &': {
      ...dark,
    },
  }
}

export const makeLightDarkThemeKey = (property: string, key: string) => {
  return {
    [property]: key.split(".").reduce((o, i) => o[i], theme.colorSchemes.light),
    '[data-mui-color-scheme="dark"] &': {
      [property]: key.split(".").reduce((o, i) => o[i], theme.colorSchemes.dark),
    },
  }
}
