import { SEMESTER } from "@cs124/person"
import { usePersonable } from "@cs124/personable"
import { Box, Checkbox, Chip, List, ListItem, Switch, Typography, useTheme } from "@mui/material"
import moment from "moment-timezone"
import { useRouter } from "next/router"
import React, { useEffect, useRef, useState } from "react"
import { useHash } from "../element-tracker/HashProvider"
import { A } from "../material-ui"
import makeLightDark from "../material-ui/makeLightDark"
import { useLessons } from "./LessonsProvider"

const JavaChip: React.FC<{ disabled: boolean; onClick: () => void }> = ({ disabled, onClick }) => {
  return (
    <Chip label={"Java"} size="small" onClick={onClick} disabled={!disabled} color={disabled ? "disabled" : "java"} />
  )
}

const KotlinChip: React.FC<{ disabled: boolean; onClick: () => void }> = ({ disabled, onClick }) => {
  return (
    <Chip
      label={"Kotlin"}
      size="small"
      onClick={onClick}
      disabled={!disabled}
      color={disabled ? "disabled" : "kotlin"}
    />
  )
}

export const LessonSidebar: React.FC = () => {
  const { course, loaded: courseLoaded } = usePersonable()
  const { preview, setPreview, current, lessons } = useLessons()
  const { semester, number, language } = current
  const router = useRouter()
  const pathname = router.asPath.split(/[?#]/)[0]
  const { hash } = useHash()
  const theme = useTheme()

  const [loaded, setLoaded] = useState(false)
  const [now, setNow] = useState(new Date())
  useEffect(() => {
    setLoaded(true)
    setNow(new Date())
  }, [router])

  const isStaff = course?.isStaff
  const canSeeOld = isStaff || course?.category === "alumni" || course?.category === "former staff"

  const filteredLessons = (lessons || []).filter(lesson => {
    if (lesson.language !== language || lesson.semester !== semester) {
      return false
    }
    if (preview) {
      return lesson.number <= number
    } else {
      return isStaff || now.valueOf() > moment(lesson.available).valueOf()
    }
  })

  const currentIndex = filteredLessons.findIndex(lesson => lesson.number === number)

  const previous = filteredLessons[currentIndex - 1]
  const next = filteredLessons[currentIndex + 1]
  const otherLanguage = lessons.find(
    lesson =>
      lesson.semester === semester &&
      lesson.number === number &&
      lesson.language === (language === "kotlin" ? "java" : "kotlin")
  )

  const sawElement = useRef(false)
  useEffect(() => {
    if (!courseLoaded || sawElement.current) {
      return
    }
    const element = document.getElementById(`li-${semester}-${number}`)
    if (element) {
      sawElement.current = true
      element.scrollIntoView({ block: "center" })
    }
  })

  if (semester !== SEMESTER && !canSeeOld) {
    return null
  }

  return (
    <Box sx={{ display: "flex", height: `calc(100vh - 120px)`, flexDirection: "column" }}>
      <Box sx={{ flex: 1, flexGrow: 0, paddingBottom: 2 }}>
        {isStaff && (
          <Box sx={{ textAlign: "right", marginRight: 2 }}>
            <Typography variant="caption">
              Preview:{" "}
              <Checkbox size="small" sx={{ padding: 0 }} checked={preview} onChange={() => setPreview(!preview)} />
            </Typography>
          </Box>
        )}
        <Box sx={{ marginRight: 2, display: "flex", justifyContent: "flex-end" }}>
          {previous ? (
            <A href={previous.path}>
              <Typography variant="caption" sx={{ marginRight: 2 }}>
                Prev
              </Typography>
            </A>
          ) : (
            <Typography variant="caption" sx={{ marginRight: 2, visibility: "hidden" }}>
              Prev
            </Typography>
          )}
          <A href="/lessons/">
            <Typography variant="caption">Index</Typography>
          </A>
          {next ? (
            <A href={next.path}>
              <Typography variant="caption" sx={{ marginLeft: 2 }}>
                Next
              </Typography>
            </A>
          ) : (
            <Typography variant="caption" sx={{ marginLeft: 2, visibility: "hidden" }}>
              Next
            </Typography>
          )}
        </Box>
        <Box sx={{ marginRight: 2, display: "flex", justifyContent: "flex-end", marginTop: 1 }}>
          <KotlinChip
            disabled={language === "java"}
            onClick={() => {
              language === "java" &&
                router.push({ pathname: otherLanguage.path, hash: hash.trim().length > 0 ? hash.trim() : "" })
            }}
          />
          <Switch
            size="small"
            checked={language === "java"}
            color="default"
            onChange={() => {
              router.push({ pathname: otherLanguage.path, hash: hash.trim().length > 0 ? hash.trim() : "" })
            }}
          />
          <JavaChip
            disabled={language === "kotlin"}
            onClick={() => {
              language === "kotlin" &&
                router.push({ pathname: otherLanguage.path, hash: hash.trim().length > 0 ? hash.trim() : "" })
            }}
          />
        </Box>
      </Box>
      <Box sx={{ flex: "1 1 auto", overflowY: "scroll" }}>
        <List
          dense
          sx={{
            "&:hover": {
              cursor: "pointer",
            },
            paddingBottom: 4,
          }}
        >
          {filteredLessons.reverse().map((lesson, i) => {
            const active = loaded && lesson.path.replace(/\/$/, "") === pathname.replace(/\/$/, "")
            return (
              <ListItem
                id={`li-${lesson.semester}-${lesson.number}`}
                key={i + 1}
                sx={{
                  justifyContent: "flex-end",
                  cursor: "default",
                }}
              >
                <Typography
                  variant={"h4"}
                  onClick={() => router.push(lesson.path)}
                  sx={{
                    paddingRight: 1,
                    "&:hover": {
                      ...makeLightDark(
                        {
                          borderRight: `4px solid ${theme.colorSchemes.light.palette.action.disabled}`,
                        },
                        {
                          borderRight: `4px solid ${theme.colorSchemes.dark.palette.action.disabled}`,
                        }
                      ),
                    },
                    cursor: "pointer",
                    fontSize: "1rem",
                    textAlign: "right",
                    ...(active
                      ? {
                          fontWeight: 700,
                          ...makeLightDark(
                            {
                              borderRight: `4px solid ${theme.colorSchemes.light.palette.action.selected}`,
                            },
                            {
                              borderRight: `4px solid ${theme.colorSchemes.dark.palette.action.selected}`,
                            }
                          ),
                        }
                      : { fontWeight: "normal", borderRight: "4px solid transparent" }),
                  }}
                >
                  {lesson.title} : <Typography variant="kbd">{moment(lesson.date).format("MM/DD/YYYY")}</Typography>
                </Typography>
              </ListItem>
            )
          })}
        </List>
      </Box>
    </Box>
  )
}
