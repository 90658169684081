import { Typography } from "@mui/material"
import React from "react"
import { Ace } from "../ace"
import { Code as MUICode } from "../material-ui"

export interface CodeProps {
  code: string
  mode?: string
}
export const Code: React.FC<CodeProps> = props => {
  return <Ace snippet={true} {...props} />
}

export interface PreProps {
  children: React.ReactElement
}
export const Pre: React.FC<PreProps> = ({ children }) => {
  if (children?.props?.originalType === "code") {
    return <>{children}</>
  } else {
    return <Typography variant="pre">{children}</Typography>
  }
}
export interface InlineCodeProps {
  value: string
}
export const InlineCode: React.FC<InlineCodeProps> = props => {
  return <MUICode>{props.value}</MUICode>
}
