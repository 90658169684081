import { terminalOutput } from "@cs124/jeed-output"
import { useJeed } from "@cs124/jeed-react"
import { useMace } from "@cs124/mace"
import { AceRecord, AceRecorder, record as recorder, safeChangeValue } from "@cs124/monace"
import { usePersonable } from "@cs124/personable"
import Close from "@mui/icons-material/Close"
import { Box, GlobalStyles, Typography } from "@mui/material"
import Chip from "@mui/material/Chip"
import Paper from "@mui/material/Paper"
import Skeleton from "@mui/material/Skeleton"
import { blue, grey } from "@mui/material/colors"
import { useTheme } from "@mui/material/styles"
import useTimeout from "@rooks/use-timeout"
import dynamic from "next/dynamic"
import React, { CSSProperties, useCallback, useEffect, useRef, useState } from "react"
import type { IAceEditorProps, ICommand } from "react-ace"
import { IAceEditor } from "react-ace/lib/types"
import { Literal, Number, Record as RuntypeRecord, String as RuntypeString, Static, Union } from "runtypes"
import { debounce } from "throttle-debounce"
import { DEVELOPMENT } from "../../constants"
import makeLightDark, { makeLightDarkThemeKey } from "../material-ui/makeLightDark"
import { CornerButton } from "./CornerButton"
import { ModeButton } from "./ModeButton"
import { RecordButton } from "./RecordButton"
import { RestoreButton } from "./RestoreButton"
import { RunButton } from "./RunButton"
import { SavingIndicator } from "./SavingIndicator"
import { StumperResetButton } from "./StumperResetButton"
import { injectMetadata } from "./audio"
import { AceAnnotation, compilerWarnings, createJeedJob, getComplexityAnnotations, runJeedJob } from "./jeed"

const AceEditor = dynamic(() => import("react-ace"), { ssr: false })

const DISABLED_COMMANDS = [
  {
    name: "gotoline",
    exec: (): boolean => {
      return true
    },
    bindKey: undefined,
  },
  {
    name: "insert",
    exec: (): boolean => {
      return true
    },
    bindKey: { mac: "Insert", win: "Insert" },
  },
] as ICommand[]

export interface AceProps extends IAceEditorProps {
  id?: string
  numbers?: string
  clickOut?: boolean
  displayOnly?: boolean
  initialCursorPosition?: number[]
  noMaceServer?: boolean
  noJeed?: boolean
  record?: boolean
  onChange?: (value: string) => void
  onRecordStart?: (recorder: AceRecorder) => void
  onRecordComplete?: (trace: AceTrace) => void
  onOutputChange?: (output: string) => void
  onModeChange?: (mode: string) => void
  onMaceLoaded?: () => void
  snippet?: boolean
  complexity?: boolean
  noCheckstyle?: boolean
  useContainer?: boolean
  checkForSnippet?: boolean
  maxOutputLines?: number
  wrapperStyle?: CSSProperties
  replaying?: boolean
  showOutput?: boolean
  output?: string
  outputPosition?: { top: number; left: number; height: number; width: number }
  tight?: boolean
  allowClose?: boolean
  qaSubmit?: ExternalSimpleSubmit
  qaReset?: () => void
  getControls?: GetControls
  stumperReset?: () => void
  stumperSubmit?: ExternalSubmit
  quizMode?: boolean
  verbose?: boolean
  noMace?: boolean
  submitDisabled?: boolean
  swapModes?: boolean
  jspSubmit?: ExternalSubmit
  code?: string
  staticCode?: string
  resetOutput?: boolean
  previousOutput?: boolean
}
export const Ace: React.FC<AceProps> = ({
  id,
  clickOut = true,
  displayOnly,
  initialCursorPosition,
  noMaceServer = false,
  noJeed = false,
  record = false,
  snippet = true,
  complexity = false,
  noCheckstyle = false,
  useContainer = false,
  checkForSnippet = false,
  maxOutputLines = 16,
  annotations,
  wrapperStyle = {},
  replaying = false,
  onRecordStart,
  onRecordComplete,
  tight = false,
  allowClose = true,
  qaSubmit,
  qaReset,
  stumperReset,
  stumperSubmit,
  quizMode = false,
  noMace = false,
  submitDisabled = false,
  swapModes = false,
  onOutputChange,
  onMaceLoaded,
  jspSubmit,
  ...props
}) => {
  // Styling and SSR
  const theme = useTheme()
  const gutterWidth = theme.spacing(3)
  const { course } = usePersonable()

  const [state, setState] = useState<"static" | "loading" | "loaded">("static")
  const [mode, setMode] = useState(props.mode ?? "text")

  useEffect(() => {
    setMode(props.mode)
  }, [props.mode])

  const commands = (props.commands || []).concat(DISABLED_COMMANDS) as (ICommand & { readOnly?: boolean })[]
  const setOptions = Object.assign({}, props.setOptions)
  const defaultValue =
    props.defaultValue !== undefined
      ? props.defaultValue
      : props.code
        ? Buffer.from(props.code, "base64").toString()
        : undefined

  displayOnly = displayOnly !== undefined ? displayOnly : !(mode === "java" || mode === "kotlin")
  const showPrintMargin = displayOnly ? false : (props.showPrintMargin ?? false)
  if (displayOnly) {
    setOptions.readOnly = true
    setOptions.highlightActiveLine = false
    setOptions.highlightGutterLine = false
    setOptions.fixedWidthGutter = true
  }
  setOptions.useWorker = false
  setOptions.tabSize = mode === "python" ? 4 : 2
  setOptions.useSoftTabs = true

  const numbers = props.numbers !== undefined ? props.numbers === "true" : !displayOnly

  const editor = useRef<IAceEditor>(null)

  const [editorEmpty, setEditorEmpty] = useState(defaultValue === undefined || defaultValue.trim() === "")
  const [editorContentsModified, setEditorContentsModified] = useState(false)

  // Mace integration
  const maceContext = useMace()
  const maceGetting = useRef(false)
  const maceSaving = useRef(false)
  const [maceLoading, setMaceLoading] = useState(false)
  const { start: startMaceLoading, clear: clearMaceLoading } = useTimeout(
    () => setMaceLoading(maceSaving.current || maceGetting.current),
    1024
  )
  const connectMace = !replaying && maceContext.available && !displayOnly && id !== undefined && !noMace
  const stopper = useRef(null)
  const attach = useCallback(() => {
    clearMaceLoading()
    if (!(id && editor.current)) {
      return
    }
    stopper.current && stopper.current()
    if (!noMaceServer) {
      maceGetting.current = true
      startMaceLoading()
    } else {
      onMaceLoaded && onMaceLoaded()
    }
    const onGetCompleted = () => {
      maceGetting.current = false
      clearMaceLoading()
      onMaceLoaded && onMaceLoaded()
    }
    const onSaveStarted = () => {
      maceSaving.current = true
      startMaceLoading()
    }
    const onSaveCompleted = () => {
      setMaceLoading(false)
      maceSaving.current = false
      clearMaceLoading()
    }
    const { stop } = maceContext.register({
      id,
      editor: editor.current,
      options: { useServer: !noMaceServer, onSaveStarted, onSaveCompleted, onGetCompleted },
    })
    stopper.current = stop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, maceContext, noMaceServer])

  useEffect(
    () => () => {
      stopper.current && stopper.current()
    },
    []
  )

  const [canRecord, setCanRecord] = useState(true)
  useEffect(() => {
    setCanRecord(!!(typeof navigator !== "undefined" && navigator.mediaDevices && navigator.mediaDevices.getUserMedia))
  }, [])

  const [recording, setRecording] = useState(false)
  const audioRecorder = useRef<MediaRecorder | undefined>()
  const addExternalChange = useRef<(change: Record<string, unknown>) => void | undefined>()
  const trace = useRef<AceTrace | undefined>(undefined)

  const toggleRecording = useCallback(async () => {
    if (!editor.current || !canRecord) {
      setRecording(false)
      return
    }

    if (!recording) {
      try {
        audioRecorder.current = new MediaRecorder(await navigator.mediaDevices.getUserMedia({ audio: true }))
        const ourRecorder = recorder(editor.current)
        const { stop, addExternalChange: add } = ourRecorder
        addExternalChange.current = add

        const chunks = []
        let startTime: Date
        audioRecorder.current.addEventListener("start", () => {
          startTime = new Date()
        })
        audioRecorder.current.addEventListener("dataavailable", ({ data }) => {
          chunks.push(data)
          if (!recording) {
            const editorTrace = stop()
            const stopTime = new Date()
            injectMetadata(new Blob(chunks), stopTime.valueOf() - startTime.valueOf()).then(finishedBlob => {
              const audioUrl = window.URL.createObjectURL(finishedBlob)
              trace.current = { audioUrl, editorTrace }
              onRecordComplete && onRecordComplete(trace.current)
            })
          }
        })
        onRecordStart && onRecordStart(ourRecorder)
        audioRecorder.current.start()
      } catch (err) {
        console.error(err)
        return
      }
    } else if (audioRecorder.current) {
      addExternalChange.current = undefined
      audioRecorder.current.stop()
      audioRecorder.current.stream.getTracks()[0].stop()
    }
    setRecording(!recording)
  }, [setRecording, recording, onRecordStart, onRecordComplete, canRecord])

  const connectRace = id && record && canRecord
  if (connectRace) {
    commands.push({
      name: "record",
      bindKey: { win: "Ctrl-S", mac: "Ctrl-S" },
      exec: () => !recording && toggleRecording(),
    })
  }

  // Jeed integration
  const jeedContext = useJeed()

  const [running, setRunning] = useState(false)
  const [output, setOutput] = useState<string>("")
  const [showOutput, setShowOutput] = useState(false)
  const [complexityAnnotations, setComplexityAnnotations] = useState<AceAnnotation[]>([])

  const run = useCallback(() => {
    const contents = editor.current?.getValue()
    if (!contents || (mode !== "java" && mode !== "kotlin")) {
      return
    }
    const job = createJeedJob(contents, {
      id: id || "jeed",
      mode,
      snippet,
      noCheckstyle,
      useContainer,
      checkForSnippet,
    })

    setRunning(true)
    runJeedJob(job, jeedContext)
      .then(response => {
        const { output: o } = terminalOutput(response)
        const actualOutput = o !== "" ? o : `(Completed without output)`
        const compilerWarning = compilerWarnings(response)
        const output = compilerWarning ? `${compilerWarning}\n\n${actualOutput}` : actualOutput
        addExternalChange.current && addExternalChange.current(AceOutputChange.check({ what: "output", output }))
        setOutput(output)
        setShowOutput(true)
        setRunning(false)
        setComplexityAnnotations(complexity ? getComplexityAnnotations(response) : [])
      })
      .catch(err => {
        setOutput(`Error: ${err}`)
        setShowOutput(true)
        setRunning(false)
      })
  }, [id, mode, noCheckstyle, useContainer, snippet, jeedContext, complexity, checkForSnippet])

  useEffect(() => {
    addExternalChange.current &&
      addExternalChange.current(AceShowOutputChange.check({ what: "showoutput", state: showOutput ? "open" : "close" }))
  }, [showOutput])

  useEffect(() => {
    addExternalChange.current && addExternalChange.current(AceModeChange.check({ what: "mode", mode }))
  }, [mode])

  const connectJeed =
    jeedContext.available &&
    (mode === "java" || mode === "kotlin") &&
    !noJeed &&
    !jspSubmit &&
    !qaSubmit &&
    !stumperSubmit
  if (connectJeed) {
    commands.push(
      {
        name: "run",
        bindKey: { win: "Ctrl-Enter", mac: "Ctrl-Enter" },
        exec: () => !running && run(),
        readOnly: true,
      },
      {
        name: "close",
        bindKey: { win: "Esc", mac: "Esc" },
        exec: () => setShowOutput(false),
        readOnly: true,
      }
    )
  }

  // JSP Integration
  const connectJSP = !!jspSubmit
  const jspGrade = useCallback(async () => {
    const contents = editor.current?.getValue()
    try {
      await jspSubmit(contents, setOutput, setShowOutput, setRunning)
    } catch (e) {
      console.error(e)
    }
  }, [jspSubmit])

  if (connectJSP) {
    commands.push(
      {
        name: "run",
        bindKey: { win: "Ctrl-Enter", mac: "Ctrl-Enter" },
        exec: () => jspGrade(),
      },
      {
        name: "close",
        bindKey: { win: "Esc", mac: "Esc" },
        exec: () => allowClose && setShowOutput(false),
      }
    )
  }

  // QA Integration
  const connectQA = qaSubmit !== undefined
  const qaGrade = useCallback(async () => {
    const contents = editor.current?.getValue()
    try {
      await qaSubmit(contents)
    } catch (e) {
      console.error(e)
    }
  }, [qaSubmit])

  if (connectQA) {
    commands.push(
      {
        name: "run",
        bindKey: { win: "Ctrl-Enter", mac: "Ctrl-Enter" },
        exec: () => !running && !submitDisabled && qaGrade(),
      },
      {
        name: "close",
        bindKey: { win: "Esc", mac: "Esc" },
        exec: () => allowClose && setShowOutput(false),
      }
    )
  }

  // Stumper Integration
  const connectStumper = stumperSubmit !== undefined
  const stumperGrade = useCallback(async () => {
    const contents = editor.current?.getValue()
    try {
      await stumperSubmit(contents, setOutput, setShowOutput, setRunning)
    } catch (e) {
      console.error(e)
    }
  }, [stumperSubmit])

  if (connectStumper) {
    commands.push(
      {
        name: "run",
        bindKey: { win: "Ctrl-Enter", mac: "Ctrl-Enter" },
        exec: () => !running && !submitDisabled && stumperGrade(),
      },
      {
        name: "close",
        bindKey: { win: "Esc", mac: "Esc" },
        exec: () => allowClose && setShowOutput(false),
      }
    )
  }

  useEffect(() => {
    props.output !== undefined && setOutput(props.output)
  }, [props.output, props.resetOutput])

  useEffect(() => {
    props.showOutput !== undefined && setShowOutput(props.showOutput)
  }, [props.showOutput, props.resetOutput])

  const outputRef = useRef<HTMLDivElement>(null)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const outputScrollMonitor = useCallback(
    debounce(100, () => {
      outputRef.current &&
        addExternalChange.current &&
        addExternalChange.current(
          AceOutputScrollChange.check({
            what: "scrollchange",
            top: outputRef.current.scrollTop,
            left: outputRef.current.scrollLeft,
            height: outputRef.current.offsetHeight,
            width: outputRef.current.offsetWidth,
          })
        )
    }),
    []
  )

  useEffect(() => {
    if (!props.outputPosition || !outputRef.current) {
      outputRef.current?.style.removeProperty("height")
      return
    }
    outputRef.current.scrollTop = props.outputPosition.top
    outputRef.current.scrollLeft = props.outputPosition.left
    outputRef.current.style.height = `${props.outputPosition.height}px`
  }, [props.outputPosition])

  // Nasty hack to add keyframes for output and other external events
  const outputAsRef = useRef<string>("")
  useEffect(() => {
    outputAsRef.current = output
    onOutputChange && onOutputChange(output)
  }, [output, onOutputChange])
  const showOutputAsRef = useRef<boolean>(false)
  useEffect(() => {
    showOutputAsRef.current = showOutput
  }, [showOutput])
  const modeAsRef = useRef<string>(mode as string)
  useEffect(() => {
    modeAsRef.current = mode as string
  }, [mode])

  useEffect(() => {
    if (!recording) {
      return
    }
    const change = () => {
      addExternalChange.current(AceOutputChange.check({ what: "output", output: outputAsRef.current }))
      addExternalChange.current(
        AceShowOutputChange.check({ what: "showoutput", state: showOutputAsRef.current ? "open" : "close" })
      )
      addExternalChange.current(AceModeChange.check({ what: "mode", mode: modeAsRef.current }))
      outputRef.current &&
        addExternalChange.current(
          AceOutputScrollChange.check({
            what: "scrollchange",
            top: outputRef.current.scrollTop,
            left: outputRef.current.scrollLeft,
            height: outputRef.current.offsetHeight,
            width: outputRef.current.offsetWidth,
          })
        )
    }
    const keyframeTimer = setInterval(change, 1000)
    change()
    return () => {
      clearInterval(keyframeTimer)
    }
  }, [recording])

  useEffect(() => {
    if (replaying) {
      editor.current?.setReadOnly(true)
      return
    }
    editor.current?.setReadOnly(displayOnly)
  }, [replaying, displayOnly])

  if (!tight) {
    wrapperStyle = { marginTop: 2, marginBottom: 2, ...wrapperStyle }
  }

  const buffer = useRef<string | undefined>()
  useEffect(() => setState("loading"), [])

  if (quizMode && course?.isStaff !== true && (DEVELOPMENT || course?.actualRole?.staff !== true)) {
    const copyKeys = "ctrl-c|cmd-c"
    const pasteKeys = "ctrl-v|cmd-v|ctrl-shift-v|cmd-shift-v"
    const cutKeys = "ctrl-x|cmd-x"
    commands.push({
      name: "quizModeCopy",
      bindKey: { win: copyKeys, mac: copyKeys },
      exec: editor => {
        buffer.current = editor.getCopyText()
        return 0
      },
      readOnly: true,
    })
    commands.push({
      name: "quizModePaste",
      bindKey: { win: pasteKeys, mac: pasteKeys },
      exec: editor => {
        buffer.current && editor.insert(buffer.current)
        return 0
      },
      readOnly: true,
    })
    commands.push({
      name: "quizModeCut",
      bindKey: { win: cutKeys, mac: cutKeys },
      exec: editor => {
        buffer.current = editor.getCopyText()
        const range = editor.getSelectionRange()
        editor.session.remove(range)
        editor.clearSelection()
        return 0
      },
      readOnly: true,
    })
  } else {
    if (editor.current) {
      editor.current.commands.removeCommand("quizModeCopy")
      editor.current.commands.removeCommand("quizModePaste")
      editor.current.commands.removeCommand("quizModeCut")
    }
  }

  // Handle command changes properly
  if (editor.current) {
    commands.forEach(command => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      editor.current.commands.addCommand(command as any)
    })
  }

  // Handle defaultValue changes properly
  const previousDefaultValue = useRef(null)
  useEffect(() => {
    const contents = editor.current?.getValue()
    if (contents && contents === previousDefaultValue.current) {
      safeChangeValue(editor.current, defaultValue)
    }
    previousDefaultValue.current = defaultValue
  }, [defaultValue])

  const maxLines = typeof props.maxLines === "string" ? parseInt(props.maxLines) : (props.maxLines ?? 24)
  const editorComponent = state !== "static" && (
    <AceEditor
      {...props}
      width={props.width ?? "100%"}
      fontSize={props.fontSize ?? "1rem"}
      maxLines={maxLines}
      annotations={(annotations || []).concat(complexityAnnotations)}
      mode={mode}
      onBeforeLoad={ace => {
        const staticAceVersion = `1.36.2`
        ace.config.set("basePath", `/ace-builds@${staticAceVersion}/src-min-noconflict`)
        ace.config.set("modePath", `/ace-builds@${staticAceVersion}/src-min-noconflict`)
        ace.config.set("themePath", `/ace-builds@${staticAceVersion}/src-min-noconflict`)
        props.onBeforeLoad && props.onBeforeLoad(ace)
      }}
      onChange={value => {
        defaultValue !== undefined && setEditorContentsModified(value !== defaultValue)
        setEditorEmpty(value.trim() === "")
        props.onChange && props.onChange(value)
      }}
      onLoad={e => {
        editor.current = e

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const session = e.session as any
        session.gutterRenderer = {
          getWidth: function () {
            return gutterWidth
          },
          getText: function (session: { $firstLineNumber: number }, row: number) {
            return !numbers ? "" : session.$firstLineNumber + row
          },
        }

        if (!displayOnly && initialCursorPosition) {
          e.moveCursorTo(initialCursorPosition[0], initialCursorPosition[1])
        }

        if (quizMode) {
          const events = ["dragenter", "dragover", "dragend", "dragstart", "dragleave", "drop", "contextmenu"]
          events.forEach(function (eventName) {
            e.container.addEventListener(
              eventName,
              function (ev) {
                ev.preventDefault()
                ev.stopPropagation()
              },
              true
            )
          })
          e.setOption("dragEnabled", false)
        }

        e.setHighlightActiveLine(false)
        e.setHighlightGutterLine(false)
        props.onLoad && props.onLoad(e)
        setState("loaded")
        attach()
        props.getControls && props.getControls(setOutput, setShowOutput, setRunning)
      }}
      onFocus={(event, editor: IAceEditor | undefined) => {
        !displayOnly && editor?.setHighlightActiveLine(props.highlightActiveLine || true)
        !displayOnly && editor?.setHighlightGutterLine(props.highlightActiveLine || true)
        props.onFocus && props.onFocus(event, editor)
      }}
      onBlur={(event, editor: IAceEditor | undefined) => {
        if (clickOut) {
          if (!editor.isFocused()) {
            const { row, column } = editor?.selection.getCursor() as { row: number; column: number }
            editor?.selection.setSelectionRange({ start: { row, column }, end: { row, column } })
            editor?.clearSelection()
          }
        }
        editor?.setHighlightActiveLine(false)
        editor?.setHighlightGutterLine(false)
        props.onBlur && props.onBlur(event, editor)
      }}
      commands={commands}
      defaultValue={defaultValue}
      showPrintMargin={showPrintMargin}
      setOptions={setOptions}
      className={"ace-ssr"}
    />
  )

  return (
    <Box sx={wrapperStyle}>
      <GlobalStyles
        styles={{
          ".ace_gutter-cell.ace_info": {
            backgroundImage: "none !important",
            ...makeLightDark(
              {
                backgroundColor: blue[100],
              },
              {
                backgroundColor: blue[900],
              }
            ),
          },
        }}
      />
      <Box
        sx={{
          paddingTop: displayOnly && !props.previousOutput ? 2 : 3,
          paddingBottom: displayOnly && !props.previousOutput ? 2 : 4,
          position: "relative",
          ...makeLightDarkThemeKey("backgroundColor", "palette.action.hover"),
        }}
        className={`${displayOnly ? "ace_display_only" : ""}`.trim()}
      >
        {!displayOnly && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              bottom: 0,
              backgroundColor: "rgba(0,0,0,0.05)",
              width: gutterWidth + theme.spacing(1) + 2,
            }}
          />
        )}
        <Box
          sx={{
            zIndex: 10,
            position: "absolute",
            top: 2,
            right: 2,
            display: "flex",
          }}
          key="top"
        >
          {swapModes && (
            <Chip
              size="small"
              label={mode === "kotlin" ? "Kotlin" : "Java"}
              color={mode === "kotlin" ? "kotlin" : "java"}
              sx={{ fontSize: "0.8em" }}
            />
          )}
          {connectMace && (
            <>
              {editorContentsModified &&
                editor.current &&
                defaultValue !== undefined &&
                editor.current.getValue() !== defaultValue && (
                  <RestoreButton editor={editor.current} defaultValue={defaultValue} />
                )}
              <SavingIndicator saving={maceLoading} />
            </>
          )}
        </Box>
        <Box
          sx={{
            zIndex: 10,
            position: "absolute",
            bottom: 2,
            right: 2,
            display: "flex",
          }}
        >
          {swapModes && !replaying && (
            <ModeButton
              mode={mode as string}
              toggle={() =>
                setMode(m => {
                  const newMode = m === "java" ? "kotlin" : "java"
                  props.onModeChange && props.onModeChange(newMode)
                  return newMode
                })
              }
            />
          )}
          {connectRace && !replaying && <RecordButton recording={recording} toggle={toggleRecording} />}
          {connectJeed && !replaying && (
            <RunButton running={running} disabled={submitDisabled || editorEmpty} run={run} />
          )}
          {connectJSP && !replaying && (
            <RunButton running={running} disabled={submitDisabled || editorEmpty} run={jspGrade} />
          )}
          {connectQA && !replaying && (
            <RunButton running={running} disabled={submitDisabled || editorEmpty} run={qaGrade} />
          )}
          {connectQA && !replaying && qaReset && <StumperResetButton stumperReset={qaReset} />}
          {connectStumper && !replaying && stumperReset && <StumperResetButton stumperReset={stumperReset} />}
          {connectStumper && !replaying && <RunButton running={running} disabled={submitDisabled} run={stumperGrade} />}
        </Box>
        {props.staticCode && (
          <Box
            className="ace_editor ace-ssr staticcontent"
            dangerouslySetInnerHTML={{ __html: Buffer.from(props.staticCode, "base64").toString() }}
            style={{ display: state !== "loaded" ? "block" : "none" }}
          />
        )}
        {editorComponent}
      </Box>
      {props.showOutput !== false && showOutput && (
        <Box sx={{ position: "relative", textAlign: "left" }}>
          <Paper
            variant="outlined"
            square
            sx={{
              margin: 0,
              padding: 1,
              color: grey[50],
              backgroundColor: grey.A700,
              border: "none",
              overflow: "auto",
            }}
            style={{ maxHeight: `${1.5 * maxOutputLines}em` }}
            ref={outputRef}
            onScroll={outputScrollMonitor}
          >
            {!replaying && allowClose && (
              <>
                <CornerButton size={theme.gap(2)} onClick={() => allowClose && setShowOutput(false)} />
                <Close
                  sx={{
                    fontSize: theme.spacing(2.4),
                    position: "absolute",
                    top: 0,
                    right: 0,
                    zIndex: 20,
                    color: "white",
                  }}
                  onClick={() => allowClose && setShowOutput(false)}
                />
              </>
            )}
            {running && (
              <Skeleton
                variant="rectangular"
                sx={{
                  position: "absolute",
                  zIndex: 10,
                  color: "white",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(255, 255, 255, 0.5)",
                }}
              />
            )}
            <Typography variant="pre">{output}</Typography>
          </Paper>
        </Box>
      )}
    </Box>
  )
}

export const AceOutputChange = RuntypeRecord({
  what: Literal("output"),
  output: RuntypeString,
})
export type AceOutputChange = Static<typeof AceOutputChange>

export const AceShowOutputChange = RuntypeRecord({
  what: Literal("showoutput"),
  state: Union(Literal("open"), Literal("close")),
})
export type AceShowOutputChange = Static<typeof AceShowOutputChange>

export const AceOutputScrollChange = RuntypeRecord({
  what: Literal("scrollchange"),
  top: Number,
  left: Number,
  height: Number,
  width: Number,
})
export type AceOutputScrollChange = Static<typeof AceOutputScrollChange>

export const AceModeChange = RuntypeRecord({
  what: Literal("mode"),
  mode: Union(Literal("java"), Literal("kotlin")),
})
export type AceModeChange = Static<typeof AceModeChange>

export const AceChanges = Union(AceOutputChange, AceShowOutputChange, AceOutputScrollChange, AceModeChange)
export type AceChanges = Static<typeof AceChanges>

export interface AceTrace {
  audioUrl: string
  editorTrace: AceRecord[]
}

export type ExternalSubmit = (
  content: string,
  setOutput: (output: string) => void,
  setShowOutput: (show: boolean) => void,
  setRunning: (running: boolean) => void
) => Promise<void>

export type ExternalSimpleSubmit = (content: string) => Promise<void>

export type GetControls = (
  setOutput: (output: string) => void,
  setShowOutput: (show: boolean) => void,
  setRunning: (running: boolean) => void
) => void
