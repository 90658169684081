import { usePersonable } from "@cs124/personable"
import { useSession } from "next-auth/react"
import React, { PropsWithChildren, useCallback, useContext, useEffect, useState } from "react"

export interface IdableContext {
  available: boolean
  ID?: string
  team?: string
  reload?: () => void
}
const IdableContext = React.createContext<IdableContext>({
  available: false,
})

export interface IdableProviderProps {
  server: string
}
export const IdableProvider: React.FC<PropsWithChildren<IdableProviderProps>> = ({ server, children }) => {
  const { headers } = usePersonable()
  const { status } = useSession()
  const [ID, setID] = useState<string | undefined>()
  const [team, setTeam] = useState<string | undefined>()
  const [reload, setReload] = useState(false)
  const doReload = useCallback(() => setReload(r => !r), [])

  useEffect(() => {
    if (!headers || status !== "authenticated") {
      return
    }
    fetch(`${server}/v1/idable`, {
      headers,
      credentials: "include",
    })
      .then(response => response.json())
      .then(({ ID: newID, team: newTeam }) => {
        setID(newID)
        setTeam(newTeam)
      })
  }, [server, headers, status, reload])

  return (
    <IdableContext.Provider value={{ available: true, ID, team, reload: doReload }}>{children}</IdableContext.Provider>
  )
}

export const useID = (): IdableContext => {
  return useContext(IdableContext)
}
