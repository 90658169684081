import React, { createContext, PropsWithChildren, useContext, useEffect, useState } from "react"

export interface TickerContext {
  available: boolean
  now: number
}
export const TickerContext = createContext<TickerContext>({
  available: false,
  now: 0,
})

export interface TickerProviderProps {
  rate?: number
}
export const TickerProvider: React.FC<PropsWithChildren<TickerProviderProps>> = ({ rate = 250, children }) => {
  const [now, setCount] = useState(new Date().valueOf())
  useEffect(() => {
    const timer = setInterval(() => {
      setCount(new Date().valueOf())
    }, rate)
    return () => {
      clearInterval(timer)
    }
  }, [rate])
  return <TickerContext.Provider value={{ available: true, now }}>{children}</TickerContext.Provider>
}
export const useTicker = (): TickerContext => {
  return useContext(TickerContext)
}
