import { Box, useTheme } from "@mui/material"
import IconButton from "@mui/material/IconButton"
import Tooltip from "@mui/material/Tooltip"
import { grey } from "@mui/material/colors"
import React from "react"

export const ModeButton: React.FC<{ mode: string; toggle: () => void }> = ({ mode, toggle }) => {
  const theme = useTheme()
  const radius = theme.spacing(3.7)
  return (
    <Tooltip
      title={"Switch Language"}
      PopperProps={{
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, -10],
            },
          },
        ],
      }}
      placement="top"
      sx={{ overflow: "hidden", tooltipPlacementRight: { margin: 0 } }}
    >
      <Box>
        <IconButton sx={{ lineHeight: 0, padding: 0 }} onClick={toggle} size="small">
          <Box
            component="span"
            sx={{
              width: radius,
              height: radius,
              lineHeight: radius,
              fontSize: theme.spacing(3),
              borderRadius: radius,
              backgroundColor: grey[300],
              color: grey[800],
            }}
          >
            {mode.charAt(0).toLocaleUpperCase()}
          </Box>
        </IconButton>
      </Box>
    </Tooltip>
  )
}
