import { Box, BoxProps } from "@mui/material"
import React, { useEffect, useRef, useState } from "react"
import { throttle } from "throttle-debounce"

export interface MinWidthProps extends BoxProps {
  width: number
}
export const MinWidth: React.FC<MinWidthProps> = ({ width, sx, children, ...props }) => {
  const ref = useRef<HTMLDivElement>(null)
  const [show, setShow] = useState(false)
  useEffect(() => {
    const updateSize = throttle(250, () => {
      setShow(ref.current !== null && ref.current.clientWidth > width)
    })
    updateSize()
    window.addEventListener("resize", updateSize)
    return (): void => {
      window.removeEventListener("resize", updateSize)
    }
  }, [width])

  return (
    <Box ref={ref} sx={{ visibility: show ? "visible" : "hidden", ...sx }} {...props}>
      {children}
    </Box>
  )
}
