import { usePersonable } from "@cs124/personable"
import ErrorIcon from "@mui/icons-material/Error"
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"
import InvertColors from "@mui/icons-material/InvertColors"
import MenuIcon from "@mui/icons-material/Menu"
import { Box } from "@mui/material"
import AppBar, { AppBarProps } from "@mui/material/AppBar"
import Avatar from "@mui/material/Avatar"
import Container from "@mui/material/Container"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import IconButton from "@mui/material/IconButton"
import TextField from "@mui/material/TextField"
import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import gravatar from "gravatar"
import { useSession } from "next-auth/react"
import Image from "next/image"
import Link from "next/link"
import { useRouter } from "next/router"
import React, { PropsWithChildren, useCallback, useContext, useState } from "react"
import { GRAVATAR_OPTIONS } from "../../constants"
import { HelpableStatus } from "../helpable/HelpableStatus"
import { LoginButton } from "../login"
import { useColorScheme } from "../material-ui"
import { MenuDrawer } from "./MenuDrawer"

export const ImpersonateArea: React.FC = () => {
  const [impersonateOpen, setImpersonateOpen] = useState(false)
  const { status, data } = useSession()
  const { course, impersonate } = usePersonable()

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key !== "Enter") {
        return
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let value = ((event.target as any).value as string).trim()
      if (value.endsWith("@illinois.edu")) {
        value = value.split("@")[0]
      }
      impersonate && impersonate(value)
      setImpersonateOpen(false)
      event.preventDefault()
    },
    [impersonate]
  )

  const email = data?.user?.email
  if (status !== "authenticated" || email !== "challen@illinois.edu") {
    return null
  }

  let icon = null

  if (course?.you && course?.you.email && course?.role) {
    icon = <Avatar src={gravatar.url(course?.you.email, GRAVATAR_OPTIONS)} />
  } else if (course?.you && course?.you.email) {
    icon = <HelpOutlineIcon />
  } else if (course && email) {
    icon = <ErrorIcon />
  }

  return (
    <>
      <IconButton size="small" disableFocusRipple onClick={() => setImpersonateOpen(true)}>
        {icon}
      </IconButton>
      <Dialog open={impersonateOpen} onClose={() => setImpersonateOpen(false)}>
        <DialogTitle>Set user to impersonate</DialogTitle>
        <TextField variant="outlined" autoFocus={true} onKeyDown={onKeyDown} />
      </Dialog>
    </>
  )
}

export interface TopBarContext {
  loaded: boolean
  showDrawer: boolean
  setShowDrawer?: (show: boolean) => void
}
export const TopBarContext = React.createContext<TopBarContext>({
  loaded: false,
  showDrawer: true,
})

export const TopBarProvider: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  const [showDrawer, setShowDrawer] = useState(true)

  return (
    <TopBarContext.Provider
      value={{
        loaded: true,
        showDrawer,
        setShowDrawer,
      }}
    >
      {children}
    </TopBarContext.Provider>
  )
}

export const TopBar: React.FC<AppBarProps & { showHelp?: boolean }> = ({ showHelp, ...props }) => {
  const theme = useTheme()
  const { colorScheme, setColorScheme } = useColorScheme()
  const thin = useMediaQuery(theme.breakpoints.down("md"))

  const { showDrawer } = useTopBar()
  const [drawerOpen, setDrawerOpen] = useState(false)
  const { course } = usePersonable()
  const { pathname } = useRouter()

  return (
    <AppBar {...props}>
      <Container maxWidth={"md"} sx={{ height: theme.spacing(8), display: "flex", alignItems: "center" }}>
        <Box sx={{ position: "absolute", left: 0, paddingLeft: 1 }}>
          {showDrawer && (
            <IconButton onClick={() => setDrawerOpen(!drawerOpen)} size="large">
              <MenuIcon />
            </IconButton>
          )}
        </Box>
        <Box sx={{ textAlign: "center", flex: 1 }}>
          <Box
            component="span"
            sx={{
              fontSize: thin ? "0.7em" : "inherit",
              visibility: ["kotlin", "both"].includes(course?.language) ? "visible" : "hidden",
            }}
          >
            Kotlin
          </Box>
          <Link href="/">
            <Image alt="CS 124 Logo" src="/images/top.png" width={85} height={48} />
          </Link>
          <Box
            component="span"
            sx={{
              fontSize: thin ? "0.7em" : "inherit",
              visibility: ["java", "both"].includes(course?.language) ? "visible" : "hidden",
            }}
          >
            Java
          </Box>
        </Box>
        <Box sx={{ position: "absolute", right: 0, paddingRight: 2 }}>
          {!thin && <ImpersonateArea />}
          {!pathname.startsWith("/help") && showHelp !== false && <HelpableStatus style="icon" />}
          <IconButton
            disableFocusRipple
            onClick={(): void => {
              setColorScheme(colorScheme === "light" ? "dark" : "light")
            }}
            size="medium"
            sx={{ marginRight: 1 }}
          >
            <InvertColors />
          </IconButton>
          <LoginButton iconOnly={thin} />
        </Box>
      </Container>
      <MenuDrawer open={drawerOpen} onClose={() => setDrawerOpen(false)} close={() => setDrawerOpen(false)} />
    </AppBar>
  )
}
export const useTopBar = (): TopBarContext => {
  return useContext(TopBarContext)
}
