import { PropsWithChildren } from "react"
import { usePopperTooltip } from "react-popper-tooltip"

export const Footnote: React.FC<PropsWithChildren<{ counter: string }>> = ({ counter, children }) => {
  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip(
    {
      placement: "top",
      delayHide: 100,
      interactive: true,
    },
    {
      modifiers: [
        {
          name: "flip",
          options: {
            padding: 90,
          },
        },
      ],
    }
  )

  return (
    <>
      <sup className="footnote" ref={setTriggerRef}>
        (<span className="inner">{counter}</span>)
      </sup>
      {visible && (
        <span ref={setTooltipRef} {...getTooltipProps({ className: "tooltip-container responsive" })}>
          <span>{children}</span>
          <span {...getArrowProps({ className: "tooltip-arrow" })} />
        </span>
      )}
    </>
  )
}
