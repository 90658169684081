import React from "react"
import { CURRENT_NUMBER } from "../constants"

import Head from "next/head"
import { useRouter } from "next/router"

export const SEO: React.FC<{ title: string; description: string }> = ({ title, description }) => {
  const { asPath } = useRouter()
  const actualTitle = `CS 124: ${title}`
  return (
    <Head>
      <title>{actualTitle}</title>
      <meta property="og:title" content={`CS ${CURRENT_NUMBER}: ${title}`} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content="https://www.cs124.org/images/logo-120x120.png" />
      <meta property="og:url" content={`https://www.cs124.org${asPath}`} />
      <meta property="og:description" content={description} />
    </Head>
  )
}
