import { Decoder, Reader, tools } from "ts-ebml"
import { DEVELOPMENT } from "../../constants"
import { fixWebmDuration } from "./fix-webm-duration"

const readAsArrayBuffer = function (blob): Promise<ArrayBuffer> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsArrayBuffer(blob)
    reader.onloadend = () => {
      resolve(reader.result as ArrayBuffer)
    }
    reader.onerror = err => {
      reject(err)
    }
  })
}

export const injectMetadata = function (blob: Blob, duration: number): Promise<Blob> {
  const decoder = new Decoder()
  const reader = new Reader()
  reader.logging = false
  reader.drop_default_duration = false

  return readAsArrayBuffer(blob).then(async buffer => {
    const elms = decoder.decode(buffer)
    elms.forEach(elm => {
      reader.read(elm)
    })
    reader.stop()
    const refinedMetadataBuf = tools.makeMetadataSeekable(reader.metadatas, reader.duration, reader.cues)
    const body = buffer.slice(reader.metadataSize)
    const result = new Blob([refinedMetadataBuf, body], { type: blob.type })

    const fixedResult = await fixWebmDuration(result, duration, { logger: DEVELOPMENT })
    return fixedResult
  })
}
