import { Languages } from "@cs124/person"
import { usePersonable } from "@cs124/personable"
import { Alert, Box, TextField } from "@mui/material"
import FormControl from "@mui/material/FormControl"
import MenuItem from "@mui/material/MenuItem"
import { useSession } from "next-auth/react"
import React, { ChangeEvent } from "react"
import { LoginButton } from "../login"
import { P } from "../material-ui"

export const StaffLanguage: React.FC = () => {
  const { status } = useSession()
  const { course, updateLanguage } = usePersonable()

  if (status !== "authenticated") {
    return (
      <Alert severity="error">
        <P>Please log in choose your language.</P>
        <LoginButton sx={{ marginTop: 1 }} />
      </Alert>
    )
  }

  if (!course?.isStaff) {
    return (
      <Alert severity="error">
        <P>Only CS 124 staff can select a language.</P>
      </Alert>
    )
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    updateLanguage(Languages.check(event.target.value))
  }

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box>
        <P sx={{ marginBottom: 0 }}>I feel comfortable tutoring students with</P>
      </Box>
      <Box>
        <FormControl variant="filled" sx={{ marginLeft: 1, minWidth: 140 }}>
          <TextField
            size="small"
            margin="none"
            select
            value={course?.language || ""}
            onChange={handleChange}
            label="Language"
          >
            <MenuItem value={"kotlin"}>Only Kotlin</MenuItem>
            <MenuItem value={"java"}>Only Java</MenuItem>
            <MenuItem value={"both"}>Both Kotlin and Java</MenuItem>
          </TextField>
        </FormControl>
      </Box>
    </Box>
  )
}
