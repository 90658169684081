import assert from "assert"
import React from "react"
import { A } from "../material-ui/"
import { useLessons } from "./LessonsProvider"

export const LessonLink: React.FC<{ url: string }> = props => {
  const { current, lessons } = useLessons()
  const { semester, language } = current
  let url = props.url
  assert(url.startsWith("~/"))
  const parts = url.slice(2).split("#")
  const anchor = parts.length === 2 ? parts[1] : ""
  const name = parts[0].replace(/\/$/, "")
  const lesson = lessons.find(
    lesson => lesson.semester === semester && lesson.language === language && lesson.name === name
  )
  assert(lesson)
  url = `${lesson.path}${anchor !== "" ? `#${anchor}` : ""}`
  return <A {...props} href={url} />
}
