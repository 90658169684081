import { usePersonable } from "@cs124/personable"
import DeleteIcon from "@mui/icons-material/Delete"
import { Typography } from "@mui/material"
import Box from "@mui/material/Box"
import Rating from "@mui/material/Rating"
import { useSession } from "next-auth/react"
import React, { useEffect, useRef } from "react"
import { SHAREABLE_SERVER } from "../../constants"

const labels: { [index: string]: string } = {
  1: "Confusing",
  2: "Unhelpful",
  3: "OK",
  4: "Great",
  5: "Excellent",
}

export const ShareableRating: React.FC<{ id: string }> = ({ id }) => {
  const [value, setValue] = React.useState<number | null>(null)
  const [hover, setHover] = React.useState(-1)
  const { headers } = usePersonable()
  const serverValue = useRef<number | undefined>(undefined)
  const { status } = useSession()
  const loggedIn = status === "authenticated"

  useEffect(() => {
    if (!loggedIn) {
      return
    }
    fetch(`${SHAREABLE_SERVER}/rating/${id}`, {
      headers,
      credentials: "include",
    }).then(async response => {
      const rating = await response.json()
      setValue(rating.rating ? rating.rating : -1)
      serverValue.current = rating.rating ? rating.rating : -1
    })
  }, [loggedIn, id, headers])

  useEffect(() => {
    if (!loggedIn || !value || value === serverValue.current) {
      return
    }
    fetch(`${SHAREABLE_SERVER}/rating/`, {
      method: "POST",
      headers: {
        ...headers,
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({ id, rating: value || -1 }),
    }).then(() => {
      serverValue.current = value
    })
  }, [loggedIn, value, headers, id])

  if (!loggedIn) {
    return null
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: 40,
      }}
    >
      <Rating
        name={`shareable-rating-${id}`}
        value={!value || value === -1 ? null : value}
        precision={1}
        onChange={(_event, newValue) => {
          newValue && setValue(newValue)
        }}
        onChangeActive={(_event, newHover) => {
          setHover(newHover)
        }}
      />
      <Box sx={{ margin: 0, padding: 0, display: "flex" }}>
        <Typography variant="caption" mx={{ fontSize: "0.8em", marginTop: -1 }} display="block">
          {labels[hover !== -1 ? hover : value] || "Rate Explanation"}
        </Typography>
        {value && value !== -1 && hover === -1 && hover !== value && (
          <DeleteIcon fontSize={"inherit"} sx={{ marginBottom: "-2px" }} onClick={() => setValue(-1)} />
        )}
      </Box>
    </Box>
  )
}
