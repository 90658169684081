import { Lesson } from "@cs124/lesson"
import { useRouter } from "next/router"
import React, { PropsWithChildren, useContext, useEffect, useMemo, useState } from "react"

export interface LessonsProps {
  lessons: Lesson[]
}
export interface LessonsContext {
  current?: Lesson
  lessons: Lesson[]
  preview: boolean
  walkthroughSeen: boolean
  setPreview: (preview: boolean) => void
  setWalkthroughSeen: (seen: boolean) => void
  loaded: boolean
  bySemester: {
    [key: string]: { [key: string]: { byNumber: { [key: number]: Lesson }; byName: { [key: string]: Lesson } } }
  }
  isLesson: boolean
}
export const LessonsContext = React.createContext<LessonsContext>({
  lessons: [],
  preview: false,
  walkthroughSeen: true,
  loaded: false,
  setPreview: () => {
    throw "Not available"
  },
  setWalkthroughSeen: () => {
    throw "Not available"
  },
  bySemester: {},
  isLesson: false,
})
export const LessonsProvider: React.FC<PropsWithChildren<LessonsProps>> = ({ lessons, children }) => {
  const { pathname } = useRouter()
  const current = useMemo(() => lessons.find(l => l.path === pathname), [lessons, pathname])

  const bySemester = useMemo(() => {
    const _bySemester = {}
    for (const lesson of lessons) {
      const { semester, language, number, name } = lesson
      if (!_bySemester[semester]) {
        _bySemester[semester] = {}
      }
      if (!_bySemester[semester][language]) {
        _bySemester[semester][language] = { byName: {}, byNumber: {} }
      }
      _bySemester[semester][language].byNumber[number] = lesson
      _bySemester[semester][language].byName[name] = lesson
    }
    return _bySemester
  }, [lessons])

  const [walkthroughSeen, setWalkthroughSeen] = useState<boolean | undefined>()
  useEffect(() => {
    setWalkthroughSeen(localStorage.getItem("lesson:walkthroughSeen") ? true : false)
  }, [])

  useEffect(() => {
    walkthroughSeen === true && localStorage.setItem("lesson:walkthroughSeen", "true")
  }, [walkthroughSeen])

  const [preview, setPreview] = useState(false)
  useEffect(() => {
    setPreview(localStorage.getItem("lesson:preview") ? true : false)
  }, [])

  useEffect(() => {
    preview ? localStorage.setItem("lesson:preview", "true") : localStorage.removeItem("lesson:preview")
  }, [preview])

  return (
    <LessonsContext.Provider
      value={{
        loaded: true,
        current: current ?? ({} as Lesson),
        preview,
        setPreview,
        setWalkthroughSeen,
        walkthroughSeen,
        lessons,
        bySemester,
        isLesson: !!current,
      }}
    >
      {children}
    </LessonsContext.Provider>
  )
}
export const useLessons = (): LessonsContext => {
  return useContext(LessonsContext)
}
export const useLesson = (): Lesson => {
  const lessons = useLessons()
  return lessons.current ?? ({} as Lesson)
}
