import { Container, ContainerProps } from "@mui/material"
import { TOPBAR_OFFSET } from "."

export const MainContainer: React.FC<ContainerProps> = props => (
  <Container
    {...props}
    sx={{
      paddingTop: `${TOPBAR_OFFSET}px`,
      paddingBottom: 8,
    }}
  />
)
