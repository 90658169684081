import { useColorScheme as muiUseColorScheme } from "@mui/material/styles"
import React, { PropsWithChildren, useCallback, useContext, useEffect } from "react"

export interface ThemeContext {
  colorScheme: string
  setColorScheme: (scheme: string) => void
}
export const ThemeContext = React.createContext<ThemeContext>({
  colorScheme: "light",
  setColorScheme: () => {
    throw `Context provider not available`
  },
})

export const ThemeProvider: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  const { mode, setMode } = muiUseColorScheme()

  const setColorScheme = useCallback(
    (colorScheme: string) => {
      setMode(colorScheme === "light" ? "light" : "dark")
      localStorage.setItem("ThemeProvider:savedColorScheme", colorScheme)
    },
    [setMode]
  )
  useEffect(() => {
    document.body.classList.add(mode === "dark" ? "dark-mode" : "light-mode")
    document.body.classList.remove(mode === "dark" ? "light-mode" : "dark-mode")
  }, [mode])

  return <ThemeContext.Provider value={{ colorScheme: mode, setColorScheme }}>{children}</ThemeContext.Provider>
}
export const useColorScheme = (): ThemeContext => useContext(ThemeContext)
