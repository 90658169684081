import React, { PropsWithChildren, useCallback, useContext, useEffect, useRef, useState } from "react"

export interface ShareableContext {
  showCaptions: boolean
  setShowCaptions: (show: boolean) => void
  changeCaptions: (srt: string, name: string) => void
  playbackRate: number
  setPlaybackRate: (rate: number) => void
  volume: number
  setVolume: (volume: number) => void
  muted: boolean
  setMuted: (muted: boolean) => void
}
export const ShareableContext = React.createContext<ShareableContext>({
  showCaptions: false,
  setShowCaptions: () => {},
  changeCaptions: () => {},
  playbackRate: 1.0,
  setPlaybackRate: () => {},
  volume: 1.0,
  setVolume: () => {},
  muted: false,
  setMuted: () => {},
})
export const ShareableProvider: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  const [showCaptions, setShowCaptions] = useState(false)
  const [playbackRate, setPlaybackRate] = useState(1.0)
  const [volume, _setVolume] = useState(1.0)
  const oldVolume = useRef(1.0)
  const [muted, _setMuted] = useState(false)

  const broadcastChannel = useRef<BroadcastChannel>(null)
  useEffect(() => {
    try {
      broadcastChannel.current = new BroadcastChannel("captions")
    } catch (err) {}
  }, [])
  const changeCaptions = useCallback((srt: string, name: string) => {
    broadcastChannel.current?.postMessage({ srt, name })
  }, [])

  useEffect(() => {
    setShowCaptions(localStorage.getItem("shareable:showCaptions") ? true : false)
  }, [])
  useEffect(() => {
    showCaptions
      ? localStorage.setItem("shareable:showCaptions", "true")
      : localStorage.removeItem("shareable:showCaptions")
  }, [showCaptions])

  useEffect(() => {
    setPlaybackRate(parseFloat(localStorage.getItem("shareable:playbackRate") ?? "1.0"))
  }, [])
  useEffect(() => {
    localStorage.setItem("shareable:playbackRate", playbackRate.toString())
  }, [playbackRate])

  useEffect(() => {
    _setVolume(parseFloat(localStorage.getItem("shareable:volume") ?? "1.0"))
  }, [])
  useEffect(() => {
    localStorage.setItem("shareable:volume", volume.toString())
  }, [volume])

  useEffect(() => {
    _setMuted(localStorage.getItem("shareable:muted") ? true : false)
  }, [])
  useEffect(() => {
    muted ? localStorage.setItem("shareable:muted", "true") : localStorage.removeItem("shareable:muted")
  }, [muted])

  const setVolume = useCallback((volume: number) => {
    oldVolume.current = volume
    _setVolume(volume)
  }, [])

  const setMuted = useCallback((muted: boolean) => {
    if (muted) {
      _setVolume(0.0)
    } else {
      _setVolume(oldVolume.current)
    }
    _setMuted(muted)
  }, [])

  return (
    <ShareableContext.Provider
      value={{
        showCaptions,
        setShowCaptions,
        changeCaptions,
        playbackRate,
        setPlaybackRate,
        volume,
        setVolume,
        muted,
        setMuted,
      }}
    >
      {children}
    </ShareableContext.Provider>
  )
}
export const useShareable = (): ShareableContext => {
  return useContext(ShareableContext)
}
