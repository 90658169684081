import { usePersonable } from "@cs124/personable"
import { UpdateSharing } from "@cs124/shareable"
import { useSession } from "next-auth/react"

import React, { PropsWithChildren, useCallback, useContext, useEffect, useState } from "react"
import { SHAREABLE_SERVER } from "../../constants"

export interface SharingContext {
  isSharing: boolean | undefined
  contributionCount: number
  setSharing: (share: boolean) => void
  updateSharing: () => void
  loaded: boolean
}
export const SharingContext = React.createContext<SharingContext>({
  isSharing: undefined,
  contributionCount: 0,
  setSharing: () => 0,
  updateSharing: () => 0,
  loaded: false,
})
export const SharingProvider: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  const [isSharing, setIsSharing] = useState<boolean | undefined>()
  const [count, setCount] = useState(0)
  const [updateCount, setUpdateCount] = useState(false)
  const { headers, course } = usePersonable()
  const { status } = useSession()
  const [loaded, setLoaded] = useState(false)

  const setSharing = useCallback(
    (sharing: boolean) => {
      if (status !== "authenticated") {
        return
      }
      fetch(`${SHAREABLE_SERVER}/sharing`, {
        headers,
        credentials: "include",
        method: "POST",
        body: JSON.stringify(UpdateSharing.check({ sharing })),
      })
        .then(r => r.json())
        .then(({ sharing, count }) => {
          setIsSharing(sharing)
          setCount(count)
        })
    },
    [headers, status]
  )

  const shouldCheck = status === "authenticated" && course?.category === "staff"

  useEffect(() => {
    if (!shouldCheck) {
      return
    }
    setLoaded(false)
    fetch(`${SHAREABLE_SERVER}/sharing`, { headers, credentials: "include" })
      .then(r => r.json())
      .then(({ sharing, count }) => {
        setIsSharing(sharing)
        setCount(count)
      })
      .catch(() => {
        setIsSharing(undefined)
        setCount(0)
      })
      .finally(() => {
        setLoaded(true)
      })
  }, [headers, updateCount, shouldCheck])

  const updateSharing = useCallback(() => {
    setUpdateCount(c => !c)
  }, [])

  return (
    <SharingContext.Provider
      value={{
        isSharing,
        contributionCount: count,
        setSharing,
        updateSharing,
        loaded,
      }}
    >
      {children}
    </SharingContext.Provider>
  )
}
export const useSharing = (): SharingContext => {
  return useContext(SharingContext)
}
