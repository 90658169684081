import { Alert, capitalize } from "@mui/material"
import { useSession } from "next-auth/react"
import React, { useEffect, useState } from "react"
import { LoginButton } from "../login"
import { P } from "../material-ui"
import { useID } from "./IdableProvider"

export const ShowTeam: React.FC = () => {
  const { status } = useSession()
  const { ID, team } = useID()
  const [hasMounted, setHasMounted] = useState(false)

  useEffect(() => {
    setHasMounted(true)
  }, [])

  if (!hasMounted || !ID) {
    return null
  }
  if (status !== "authenticated") {
    return (
      <Alert severity="error">
        <P>Please log in to see your MP team.</P>
        <LoginButton sx={{ marginTop: 1 }} />
      </Alert>
    )
  }
  return (
    <Alert severity="info">
      <P>You&apos;re on the {capitalize(team)} Team.</P>
    </Alert>
  )
}
