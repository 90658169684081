import Restore from "@mui/icons-material/Restore"
import { Box, IconButton, useTheme } from "@mui/material"
import Tooltip from "@mui/material/Tooltip"
import { grey } from "@mui/material/colors"
import React from "react"

export const StumperResetButton: React.FC<{ stumperReset: () => void }> = ({ stumperReset }) => {
  const theme = useTheme()
  return (
    <Tooltip
      title={"Reset Question"}
      placement="top"
      PopperProps={{
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, -10],
            },
          },
        ],
      }}
      sx={{ overflow: "hidden", tooltipPlacementLeft: { margin: 0 } }}
    >
      <Box>
        <IconButton size={"small"} sx={{ lineHeight: 0, padding: 0 }} onClick={stumperReset}>
          <Restore sx={{ display: "block", color: grey[500], fontSize: theme.spacing(4) }} />
        </IconButton>
      </Box>
    </Tooltip>
  )
}
