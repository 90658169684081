import { Box } from "@mui/material"
import { grey } from "@mui/material/colors"
import React, { CSSProperties } from "react"
import makeLightDark from "../material-ui/makeLightDark"

export interface CornerButtonProps extends React.HTMLAttributes<HTMLDivElement> {
  size: number
  style?: CSSProperties
}
export const CornerButton: React.FC<CornerButtonProps> = ({ size, style = {}, ...props }) => {
  return (
    <Box
      {...props}
      sx={{
        display: "block",
        width: size,
        height: size,
        borderStyle: "solid",
        borderWidth: `0 ${size * 2}px ${size * 2}px 0`,
        position: "absolute",
        top: 0,
        right: 0,
        zIndex: 10,
        ...makeLightDark(
          { borderColor: `transparent ${grey[400]} transparent transparent` },
          { borderColor: `transparent ${grey[500]} transparent transparent` }
        ),
        ...style,
      }}
    />
  )
}
